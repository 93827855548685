import './index.scss';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import Overlay from '../../../../components/layout/overlay';
import Logo from '../../../../assets/logo/QRrobo version 1 - white.png';
import CheckoutReview from '../shop-checkout-review';
import { useLocation } from 'react-router-dom';

function ShopCheckoutBackUrlPage({ type }) {
  const pathname = useLocation();

  return createPortal(
    <>
      {type === 'success' && (
        <div className='shop_checkout_back_url_page'>
          <div className='shop_checkout_back_url_page_in'>
            <div className='shop_account'>
              <div className='shop_account_header'>
                <img src={Logo} alt='qrrobo' />
              </div>
              <CheckoutReview
                invoiceId={
                  pathname.pathname.split('/')[
                    pathname.pathname.split('/').length - 1
                  ]
                }
              />
            </div>
          </div>
        </div>
      )}
      <Overlay isTransparent={true} />
    </>,
    document.getElementById('modal')
  );
}

export default memo(ShopCheckoutBackUrlPage);
