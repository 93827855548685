import './index.scss';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import ShopContext from '../../../../../../context/shop/shop-context';
import { IoIosArrowBack, IoIosRemove } from 'react-icons/io';
import { toPriceWithCurrency } from '../../../../../../utils';
import PreferencesContext from '../../../../../../context/preferences/preferences-context';
import { useTranslation } from 'react-i18next';
import SwipeableComponent from '../../../../../../components/layout/swipeable';
import Overlay from '../../../../../../components/layout/overlay';
import { GrFormAdd } from 'react-icons/gr';

function EditShopCategoryProductDetails({
  basketItem,
  setBasketItem,
  hasOverlay = true,
  isDarkMode,
  product,
  setProduct,
}) {
  const { list } = useContext(PreferencesContext);
  const { onEditItem } = useContext(ShopContext);
  const { t } = useTranslation();
  const [isMount, setIsMount] = useState(false);
  const [note, setNote] = useState(basketItem.note);
  const [qty, setQty] = useState(basketItem.qty);
  const [options, setOptions] = useState(
    product.options.map((option) => {
      const existingOption = basketItem.options.find(
        (item) => item.id === option.id
      );
      return existingOption
        ? existingOption
        : {
            ...option,
            isActive: false,
          };
    })
  );

  const updateOptions = (options, extra, product) => {
    return options.map((option) => {
      if (option.id === extra.id) {
        return {
          ...option,
          isActive:
            product.optionSelectionType === 'single' || !option.isActive,
        };
      }
      return product.optionSelectionType === 'single'
        ? { ...option, isActive: false }
        : option;
    });
  };
  const onChangeExtraStatus = useCallback(
    (extra) => {
      setOptions((prevOptions) => updateOptions(prevOptions, extra, product));
    },
    [product, setOptions]
  );
  const onEditBasketItem = useCallback(() => {
    const updatedItem = {
      ...basketItem,
      qty: qty,
      note: note,
      options: options,
    };
    onEditItem(updatedItem);
    setBasketItem(null);
    setProduct(null);
  }, [basketItem, note, onEditItem, options, qty, setBasketItem, setProduct]);

  useEffect(() => {
    setIsMount(true);
  }, []);

  return createPortal(
    <>
      <SwipeableComponent setter={setProduct} setObject={setBasketItem}>
        <div
          className={cn(
            'edit_shop_category_product_details',
            { 'edit_shop_category_product_details--show': isMount },
            { 'edit_shop_category_product_details--dark': false }
          )}
        >
          <div className='shop_checkout_header'>
            <IoIosArrowBack
              onClick={() => {
                setBasketItem(null);
                setProduct(null);
              }}
            />
            <h2>
              {t('general.edit')} {product.name}
            </h2>
          </div>
          <div className='edit_shop_category_product_details_in'>
            <div className='edit_shop_category_product_details_img'>
              <img src={basketItem.src} alt={basketItem.name} />
            </div>
            <div className='edit_shop_category_product_details_wrapper'>
              <h3 className='edit_shop_category_product_details_wrapper_name'>
                {basketItem.name}
              </h3>
              {basketItem.description && (
                <p className='edit_shop_category_product_details_wrapper_description'>
                  {basketItem.description}
                </p>
              )}
              {basketItem.price > 0 && (
                <p className='edit_shop_category_product_details_wrapper_price'>
                  {toPriceWithCurrency(
                    Number(basketItem.price),
                    list.restaurantInfo.currency
                  )}
                </p>
              )}
            </div>
            {!!basketItem.options.length && (
              <div className='edit_shop_category_product_details_extras'>
                {options.map((extra) => (
                  <div
                    className='edit_shop_category_product_details_extras_extra'
                    key={extra.id}
                    onClick={() => onChangeExtraStatus(extra)}
                  >
                    <input
                      id={extra.id}
                      type={'checkbox'}
                      checked={extra.isActive}
                      onChange={() => onChangeExtraStatus(extra)}
                    />
                    <p className='edit_shop_category_product_details_extras_extra_name'>
                      {extra.name}
                    </p>
                    <p className='edit_shop_category_product_details_extras_extra_price'>
                      {toPriceWithCurrency(
                        Number(extra.price),
                        list.restaurantInfo.currency
                      )}
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className='edit_shop_category_product_details_note'>
              <textarea
                id={'note'}
                value={note}
                maxLength={100}
                minLength={0}
                onFocus={() => (window.document.body.style.zoom = 1)}
                placeholder={t('shop.noteDescription')}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className='edit_shop_category_product_details_footer'>
            <button
              className='edit_shop_category_product_details_btn edit_shop_category_product_details_btn--remove'
              onClick={() => setQty(qty > 0 ? qty - 1 : 0)}
            >
              <IoIosRemove />
            </button>
            <span className='shop_category_product_basket_qty'>x{qty}</span>
            <div
              className='edit_shop_category_product_details_btn edit_shop_category_product_details_btn--add'
              onClick={() => setQty(qty + 1)}
            >
              <GrFormAdd />
            </div>
            <button
              className='edit_shop_category_product_details_footer_btn'
              onClick={onEditBasketItem}
            >
              {t('general.edit')}
            </button>
          </div>
        </div>
      </SwipeableComponent>
      {hasOverlay && (
        <Overlay
          onClick={() => {
            setBasketItem(null);
            setProduct(null);
          }}
          isBLurDark={true}
        />
      )}
    </>,
    document.getElementById('modal')
  );
}

export default memo(EditShopCategoryProductDetails);
