import './index.scss';
import { memo, useContext } from 'react';
import { toPriceWithCurrency } from '../../../../../utils';
import PreferencesContext from '../../../../../context/preferences/preferences-context';
import { GrFormAdd } from 'react-icons/gr';
import cn from 'classnames';
import ShopContext from '../../../../../context/shop/shop-context';

function ShopCategoryProductSuggestion({
  products,
  isDarkMode,
  setSelectedProduct,
  setShowProductDetailsPage,
}) {
  const { list } = useContext(PreferencesContext);
  const { onAddItem } = useContext(ShopContext);

  return (
    <div
      className={cn('shop_category_product_suggestion', {
        'shop_category_product_suggestion--dark': false,
      })}
    >
      {products.map((product, index) => (
        <div key={index} className='shop_category_product_suggestion_card'>
          <div className='shop_category_product_suggestion_card_img'>
            <img src={product.src} alt={product.name} />
          </div>
          <p className='shop_category_product_suggestion_card_price'>
            {toPriceWithCurrency(
              Number(product.price),
              list.restaurantInfo.currency
            )}
          </p>
          <p className='shop_category_product_suggestion_card_name'>
            {product.name}
          </p>
          <button
            className='shop_category_product_suggestion_card_btn'
            onClick={() => {
              if (!product.options.length) {
                return onAddItem({
                  ...product,
                  options: [],
                  note: product.note ? product.note : '',
                });
              }
              setSelectedProduct(product);
              return setShowProductDetailsPage(true);
            }}
          >
            <GrFormAdd />
          </button>
        </div>
      ))}
    </div>
  );
}

export default memo(ShopCategoryProductSuggestion);
