import { memo, useContext, useEffect } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import NotFound404 from '../../../assets/not-found/404.png';
import AuthContext from '../../../context/auth/auth-context';

function NotFound() {
  const navigate = useNavigate();
  const { isAuth } = useContext(AuthContext);

  useEffect(() => {
    return navigate('/not-found');
  }, [navigate]);

  return (
    <div className='not_found'>
      <div className='not_found_img'>
        <img src={NotFound404} alt='' />
      </div>
      <p>Page was not found</p>
      <button
        onClick={() => {
          if (isAuth) {
            return navigate('/');
          }
          if (isAuth === false) {
            return navigate('/auth');
          }
        }}
      >
        Back to Home
      </button>
    </div>
  );
}

export default memo(NotFound);
