import React from 'react';

const ShopContext = React.createContext({
  products: [],
  onAddItem: () => {},
  onEditItem: () => {},
  onRemoveItem: () => {},
  onRemoveItemAll: () => {},
  onRemoveItemSingleItem: () => {},
  onClear: () => {},
});

export default ShopContext;
