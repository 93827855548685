import React, { memo, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import NotFound from './pages/dashboard/not-found';
import Restaurant from './pages/dashboard/restaurant';
import LiveOrders from './pages/dashboard/live-orders';
import Orders from './pages/dashboard/orders';
import Staff from './pages/dashboard/staff';
import Tables from './pages/dashboard/tables';
import Auth from './pages/auth';
import Shop from './pages/shop';
import AuthContext from './context/auth/auth-context';
import ShopHome from './pages/shop/shop-home';
import Integrations from './pages/dashboard/integrations';
import Analytics from './pages/dashboard/analytics';
import Customers from './pages/dashboard/customers';
import Menu from './pages/dashboard/menu';
import PaymentMethods from './pages/dashboard/payment-methods';
import Branches from './pages/dashboard/branches';
import Reviews from './pages/dashboard/reviews';
import ShopCheckoutBackUrlPage from './pages/shop/shop-checkout/shop-checkout-back-url-page';
import Customer from './pages/dashboard/customers/customer';
import ShopCustomization from './pages/dashboard/shop-customization';

function Router() {
  const { isAuth } = useContext(AuthContext);

  return (
    <Routes>
      {!isAuth && (
        <>
          <Route path='*' element={<NotFound />} />
          <Route path='/auth' element={<Auth type={'sign-in'} lng={'us'} />} />
          <Route
            path='/auth/sign-up'
            element={<Auth type={'sign-up'} lng={'us'} />}
          />
          <Route
            path='/auth/sign-up/am'
            element={<Auth type={'sign-up'} lng={'am'} />}
          />
          <Route
            path='/auth/sign-up/ru'
            element={<Auth type={'sign-up'} lng={'ru'} />}
          />
        </>
      )}
      {isAuth && (
        <Route path='/' element={<Dashboard />}>
          <Route path='/' element={<LiveOrders />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/orders' element={<Orders />} />
          <Route path='/customers' element={<Customers />} />
          <Route path='/customers/:id' element={<Customer />} />
          <Route path='/staff' element={<Staff />} />
          <Route path='/tables' element={<Tables />} />
          <Route path='/branches' element={<Branches />} />
          <Route path='/customization' element={<ShopCustomization />} />
          <Route path='/restaurant' element={<Restaurant />} />
          <Route path='/reviews' element={<Reviews />} />
          <Route path='/integrations' element={<Integrations />} />
          <Route path='/payment-methods' element={<PaymentMethods />} />
          <Route path='/analytics' element={<Analytics />} />
        </Route>
      )}
      <Route path='/shop/:branchId/tables/:tableId' element={<Shop />}>
        <Route path='/shop/:branchId/tables/:tableId' element={<ShopHome />} />
        <Route
          path='/shop/:branchId/tables/:tableId/error'
          element={<ShopHome />}
        />
      </Route>
      <Route
        path='/shop/:branchId/tables/:tableId/success/:invoiceId'
        element={<ShopCheckoutBackUrlPage type={'success'} />}
      />
    </Routes>
  );
}

export default memo(Router);
