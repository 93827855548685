import './index.scss';
import React, { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import api from '../../../../../../../utils/api';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function EditProductExtra({
  setSelectedProduct,
  selectedProduct,
  setEditProductExtra,
  selectedExtra,
  lng,
  lngId,
  onRefresh,
  setSelectedExtra,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState(selectedExtra.name);
  const [error, setError] = useState(null);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setError(null);

      const data = {
        name,
      };

      api
        .put(`/products/options/${selectedExtra.id}?languageId=${lngId}`, data)
        .then(({ data }) => {
          setEditProductExtra(false);
          setSelectedExtra(null);
          setSelectedProduct({
            ...selectedProduct,
            options: selectedProduct.options.map((_option) => {
              if (selectedExtra.id === _option.id) {
                return data;
              }
              return _option;
            }),
          });
          onRefresh();
        })
        .catch((err) => setError(t(`errors.${errToString(err)}`)));
    },
    [
      setSelectedExtra,
      t,
      onRefresh,
      lngId,
      name,
      selectedExtra,
      selectedProduct,
      setSelectedProduct,
      setEditProductExtra,
    ]
  );

  return createPortal(
    <div className='edit_product_extra' onClick={(e) => e.stopPropagation()}>
      <div className='edit_product_extra_in edit_product_extra_in--active'>
        <div className='edit_product_extra_in_header'>
          <div className='edit_product_extra_in_header_title'>
            <h2>
              {t('general.editExtra')} {lng?.toUpperCase()}
            </h2>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${lng?.toLowerCase()}.svg`}
              width='48'
              alt={lng}
            />
          </div>
          <i
            onClick={(e) => {
              e.stopPropagation();
              setEditProductExtra(false);
              setSelectedExtra(null);
            }}
          >
            <IoMdClose />
          </i>
        </div>
        <form className='form' onSubmit={onSubmit}>
          <div className='form_columns'>
            <div className='form_column'>
              <label htmlFor=''>{t('dashboard.menu.name')}</label>
              <input
                type='text'
                value={name}
                placeholder={t('dashboard.menu.name')}
                onChange={(e) => onChangeField(e, setName)}
              />
            </div>
          </div>
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
              {t('general.save')}
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

EditProductExtra.propTypes = {
  setEditProductExtra: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  selectedExtra: PropTypes.object.isRequired,
  lng: PropTypes.string.isRequired,
  lngId: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default memo(EditProductExtra);
