import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';
import { IoIosArrowBack } from 'react-icons/io';
import { BsShareFill } from 'react-icons/bs';
import { RWebShare } from 'react-web-share';
import api from '../../../utils/shop-api';
import Loading from '../../../components/loading';
import Overlay from '../../../components/layout/overlay';
import { errToString, openDeepLink, toPriceWithCurrency } from '../../../utils';
import PreferencesContext from '../../../context/preferences/preferences-context';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import SwipeableComponent from '../../../components/layout/swipeable';
import CheckoutReview from './shop-checkout-review';
import moment from 'moment';
import VisaMaster from '../../../assets/payment-methods/pos-shop.jpg';
import TelCell from '../../../assets/payment-methods/telcell.png';
import Idram from '../../../assets/payment-methods/idram.png';
import { GrMoney } from 'react-icons/gr';

function ShopCheckout({ tableId, branchId, setCheckoutIsOpen, lngId }) {
  const { list } = useContext(PreferencesContext);
  const { t } = useTranslation();

  const [isMount, setIsMount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoice, setInvoice] = useState(null);
  const [showReview, setShowReview] = useState(false);
  const [pending, setPending] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [customerSavedCards, setCustomerSavedCards] = useState(null);

  const onChangePayment = useCallback(
    (payment) => {
      setSelectedPaymentMethod(payment);

      return setPaymentMethods(
        paymentMethods.map((_payment) => {
          if (payment.id === _payment.id) {
            return {
              ..._payment,
              isActive: true,
            };
          }
          return {
            ..._payment,
            isActive: false,
          };
        })
      );
    },
    [paymentMethods]
  );
  const onCheckout = useDebouncedCallback((e) => {
    e.preventDefault();
    setPending(true);
    localStorage.removeItem('selectedCategoryTitle');
    if (!selectedPaymentMethod) {
      return;
    }

    if (selectedPaymentMethod.value === 'cash') {
      api
        .post(`/shop/${branchId}/call`, {
          tableId,
        })
        .then(() => {
          const audio = new Audio(sound);
          const info = t('shop.payedWithCash');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setPending(false);
          setShowReview(true);
        })
        .catch((err) => {
          setPending(false);
          alert(errToString(err));
          console.log(errToString(err));
        });
    }
    if (selectedPaymentMethod.value !== 'cash') {
      return api
        .post(`/shop/${branchId}/pay`, {
          bank: selectedPaymentMethod.value,
          invoiceId: invoice.id,
          backUrl: window.location.origin,
        })
        .then(({ data }) => {
          if (selectedPaymentMethod.tag === 'pos') {
            return window.location.replace(data.bank_order.formUrl);
          }
          if (selectedPaymentMethod.tag === 'telcell') {
            return openDeepLink(data.open_in_app, data.url);
          }
          if (selectedPaymentMethod.tag === 'idram') {
            return openDeepLink(data.open_in_app, data.url);
          }
        })
        .catch((err) => {
          setPending(false);
          alert(errToString(err));
          console.log(errToString(err));
        });
    }
  }, 300);
  const onSaveCard = useCallback((e) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    if (
      selectedPaymentMethod &&
      selectedPaymentMethod.tag === 'pos' &&
      selectedPaymentMethod.isSaveCard
    ) {
      api
        .get(`/shop/${branchId}/customer/cards`)
        .then(({ data }) => {
          return setCustomerSavedCards(data);
        })
        .catch((err) => {
          console.log(errToString(err));
        });
    }
  }, [selectedPaymentMethod, branchId]);
  useEffect(() => {
    api
      .get(`/shop/${branchId}/invoice?languageId=${lngId}`)
      .then(({ data }) => {
        setInvoice(data);
        api
          .get(`/shop/${branchId}/payment-methods`)
          .then(({ data }) => {
            setPaymentMethods(data);
            setSelectedPaymentMethod(data[0]);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            console.log(errToString(err));
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err));
      });
  }, [t, branchId, lngId]);
  useEffect(() => {
    setIsMount(true);
  }, []);

  return createPortal(
    <>
      <SwipeableComponent setter={setCheckoutIsOpen}>
        <div
          className={cn(
            'shop_checkout',
            { 'shop_checkout--show': isMount },
            { 'shop_checkout--dark': false }
          )}
        >
          <div className='shop_checkout_header'>
            <IoIosArrowBack onClick={() => setCheckoutIsOpen(false)} />
            <h2 onClick={() => setCheckoutIsOpen(false)}>
              {t('shop.checkout')}
            </h2>
            {!loading && invoice.totalAmount !== 0 && (
              <div className='shop_checkout_header_share'>
                <RWebShare
                  data={{
                    text: t('shop.sharePay'),
                    url: 'https://www.qrrobo.com',
                    title: 'qrrobo.com',
                  }}
                  onClick={() => console.log('shared successfully!')}
                >
                  <div className='shop_checkout_header_share_in'>
                    <p>{t('shop.share')}</p>
                    <BsShareFill />
                  </div>
                </RWebShare>
              </div>
            )}
          </div>
          {!loading && (
            <>
              <div className='shop_checkout_in'>
                <div className='shop_checkout_in_info'>
                  <span>
                    {t('shop.area')}: {invoice.table.area.name}
                  </span>
                  <span>
                    {t('shop.table')}: {invoice.table.name}
                  </span>
                  <span>
                    {t('shop.invoice')}: #{invoice.externalId}
                  </span>
                  <span>
                    {t('shop.guests')}: {invoice.guests.length}
                  </span>
                </div>
                {invoice.totalAmount !== 0 && selectedPaymentMethod && (
                  <>
                    <h2 className='shop_checkout_in_title'>
                      {t('shop.payment')}
                    </h2>
                    {paymentMethods.map((payment) => (
                      <div
                        key={payment.value}
                        className={cn('shop_checkout_in_payment_method', {
                          'shop_checkout_in_payment_method--selected':
                            selectedPaymentMethod.value === payment.value,
                        })}
                        onClick={() => onChangePayment(payment)}
                      >
                        {payment.tag === 'cash' && (
                          <>
                            <div className='shop_checkout_in_payment_method_icon'>
                              <GrMoney />
                            </div>
                            <p>{t(`shop.cash`)}</p>
                          </>
                        )}
                        {payment.tag === 'pos' && (
                          <>
                            <div className='shop_checkout_in_payment_method_icon'>
                              <img
                                style={{ width: '80px' }}
                                src={VisaMaster}
                                alt='Visa Master'
                              />
                            </div>
                            <p>{t(`shop.card`)}</p>
                          </>
                        )}
                        {payment.tag === 'telcell' && (
                          <>
                            <div className='shop_checkout_in_payment_method_icon'>
                              <img
                                style={{ width: '25px', margin: '0 8px 0 2px' }}
                                src={TelCell}
                                alt='TelCell'
                              />
                            </div>
                            <p>TelCell</p>
                          </>
                        )}
                        {payment.tag === 'idram' && (
                          <>
                            <div className='shop_checkout_in_payment_method_icon'>
                              <img
                                style={{ margin: '0 0 0 -6px' }}
                                src={Idram}
                                alt='Idram'
                              />
                            </div>
                            <p>Idram</p>
                          </>
                        )}
                        <input
                          type='checkbox'
                          onChange={() => onChangePayment(payment)}
                          checked={
                            selectedPaymentMethod.value === payment.value
                          }
                        />
                      </div>
                    ))}
                  </>
                )}
                {selectedPaymentMethod &&
                  selectedPaymentMethod.tag === 'pos' && (
                    <>
                      <div>
                        save card
                        <p>
                          {selectedPaymentMethod.isSaveCard
                            ? 'Can save card'
                            : 'Cannot save card'}
                        </p>
                        {/*TODO Check If customer ha already saved catrds or not and add additional logic*/}
                        <input
                          type='checkbox'
                          checked={false}
                          onChange={() => onSaveCard(selectedPaymentMethod)}
                        />
                      </div>
                      {customerSavedCards && (
                        <div>
                          {customerSavedCards.map((card, index) => (
                            <div key={index}>
                              card number: {card.maskNumber}
                              card name: {card.name}
                              card type: {card.type}- onSubmit function send
                              card client id: {card.clientId}
                            </div>
                          ))}
                        </div>
                      )}
                      {!customerSavedCards && <div>Loading...</div>}
                    </>
                  )}
                <h2
                  style={{ marginTop: '25px' }}
                  className='shop_checkout_in_title'
                >
                  {t('shop.billInfo')}
                </h2>
                <div className='shop_checkout_in_order'>
                  {invoice.guests.map((guest, index) => (
                    <div key={index}>
                      {!!guest.products.length && (
                        <>
                          <div className='shop_checkout_in_order_guest'>
                            <div className='shop_checkout_in_order_guest_btn'>
                              {t('shop.guest')} {guest.externalId}{' '}
                            </div>
                            <p>
                              {toPriceWithCurrency(
                                Number(guest.totalAmount),
                                list.restaurantInfo.currency
                              )}
                            </p>
                          </div>
                          <ul
                            className={cn(
                              'shop_checkout_in_bill shop_checkout_in_bill--open'
                            )}
                          >
                            {guest.products.map((product, index) => (
                              <li key={index}>
                                <span>
                                  {`${product.name} - ${moment(product.createdAt).format('HH:mm')}`}
                                </span>
                                <p className='orders_list_product_name_qty'>
                                  x{product.quantity}
                                </p>
                                <p>
                                  {toPriceWithCurrency(
                                    Number(
                                      product.options.reduce(
                                        (acc, option) => option.price + acc,
                                        0
                                      ) + product.price
                                    ),
                                    list.restaurantInfo.currency
                                  )}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  ))}
                  {invoice.orders.map((order, index) => (
                    <div key={index}>
                      {!!order.products.length && (
                        <>
                          <div className='shop_checkout_in_order_guest'>
                            <div className='shop_checkout_in_order_guest_btn'>
                              {t('shop.waitersOrders')}
                            </div>
                            <p>
                              {toPriceWithCurrency(
                                Number(order.totalAmount),
                                list.restaurantInfo.currency
                              )}
                            </p>
                          </div>
                          <ul
                            className={cn(
                              'shop_checkout_in_bill shop_checkout_in_bill--open'
                            )}
                          >
                            {order.products.map((product, index) => (
                              <li key={index}>
                                <span>
                                  {`${product.name} - ${moment(product.createdAt).format('HH:mm')}`}
                                </span>
                                <p className='orders_list_product_name_qty'>
                                  x{product.quantity}
                                </p>
                                <p>
                                  {toPriceWithCurrency(
                                    Number(
                                      product.options.reduce(
                                        (acc, option) => option.price + acc,
                                        0
                                      ) + product.price
                                    ),
                                    list.restaurantInfo.currency
                                  )}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                    </div>
                  ))}
                  <ul className='shop_checkout_in_bill_subtotal'>
                    <li>
                      <span>{t('shop.subtotal')}:</span>
                      <p>
                        {toPriceWithCurrency(
                          Number(invoice.totalAmount),
                          list.restaurantInfo.currency
                        )}
                      </p>
                    </li>
                    <li>
                      <span>{`${t('shop.serviceFee')} (${list.restaurantInfo.serviceFee}%):`}</span>
                      <p>
                        {toPriceWithCurrency(
                          Number(
                            (invoice.totalAmount *
                              list.restaurantInfo.serviceFee) /
                              100
                          ),
                          list.restaurantInfo.currency
                        )}
                      </p>
                    </li>
                  </ul>
                  <div className='shop_checkout_in_bill_total'>
                    <span>{t('shop.total')}:</span>
                    <p>
                      {toPriceWithCurrency(
                        Number(
                          invoice.totalAmount +
                            (invoice.totalAmount *
                              list.restaurantInfo.serviceFee) /
                              100
                        ),
                        list.restaurantInfo.currency
                      )}
                    </p>
                  </div>
                </div>
              </div>
              {invoice.totalAmount !== 0 && selectedPaymentMethod && (
                <div className='shop_checkout_actions'>
                  {!pending && (
                    <button
                      className='shop_checkout_actions_btn'
                      onClick={onCheckout}
                    >
                      {selectedPaymentMethod.value === 'cash'
                        ? t('shop.bill')
                        : t('shop.pay')}
                    </button>
                  )}
                  {pending && (
                    <button className='shop_checkout_actions_btn shop_checkout_actions_btn--loading'>
                      <Loading />
                    </button>
                  )}
                </div>
              )}
            </>
          )}
          {loading && (
            <div className={cn('')}>
              <Loading />
            </div>
          )}
        </div>
      </SwipeableComponent>
      <Overlay isTransparent={true} onClick={() => setCheckoutIsOpen(false)} />
      {showReview &&
        createPortal(
          <>
            <CheckoutReview
              setShowRating={setShowReview}
              invoiceId={invoice.id}
            />
            <div
              className='shop_checkout_review_overlay'
              onClick={() => setShowReview(false)}
            />
          </>,
          document.getElementById('modal')
        )}
    </>,
    document.getElementById('modal')
  );
}

export default memo(ShopCheckout);
