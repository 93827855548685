import './index.scss';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import ShopContext from '../../../../../../context/shop/shop-context';
import { IoIosArrowBack } from 'react-icons/io';
import { toPriceWithCurrency } from '../../../../../../utils';
import PreferencesContext from '../../../../../../context/preferences/preferences-context';
import { useTranslation } from 'react-i18next';
import SwipeableComponent from '../../../../../../components/layout/swipeable';
import Overlay from '../../../../../../components/layout/overlay';

function ShopCategoryProductDetails({
  product,
  setShowProductDetailsPage,
  hasOverlay = true,
  isDarkMode,
}) {
  const { list } = useContext(PreferencesContext);
  const { onAddItem } = useContext(ShopContext);
  const { t } = useTranslation();

  const [isMount, setIsMount] = useState(false);
  // const [containerHeight, setContainerHeight] = useState('97%');

  const [note, setNote] = useState('');
  const [options, setOptions] = useState(
    product.options.map((option) => {
      return {
        ...option,
        isActive: false,
      };
    })
  );
  const totalAmount = useMemo(() => {
    if (!product.options.length) {
      return product.price;
    }
    return (
      options
        .filter(({ isActive }) => isActive)
        .reduce((acc, val) => acc + val.price, 0) + product.price
    );
  }, [product, options]);

  const onChangeExtraStatus = useCallback(
    (extra) => {
      if (product.optionSelectionType === 'single') {
        return setOptions(
          options.map((option) => {
            if (option.id === extra.id) {
              return {
                ...option,
                isActive: true,
              };
            }
            return {
              ...option,
              isActive: false,
            };
          })
        );
      }
      if (product.optionSelectionType === 'multiple') {
        return setOptions(
          options.map((option) => {
            if (option.id === extra.id) {
              return {
                ...option,
                isActive: !option.isActive,
              };
            }
            return option;
          })
        );
      }
    },
    [options, product.optionSelectionType]
  );

  useEffect(() => {
    setIsMount(true);
  }, []);

  return createPortal(
    <>
      <SwipeableComponent setter={setShowProductDetailsPage}>
        <div
          className={cn(
            'shop_category_product_details',
            { 'shop_category_product_details--show': isMount },
            { 'shop_category_product_details--dark': false }
          )}
        >
          <div className='shop_checkout_header'>
            <IoIosArrowBack onClick={() => setShowProductDetailsPage(false)} />
            <h2>{product.name}</h2>
          </div>
          <div className='shop_category_product_details_in'>
            <div className='shop_category_product_details_img'>
              <img src={product.src} alt={product.name} />
            </div>
            <div className='shop_category_product_details_wrapper'>
              <h3 className='shop_category_product_details_wrapper_name'>
                {product.name}
              </h3>
              {product.description && (
                <p className='shop_category_product_details_wrapper_description'>
                  {product.description}
                </p>
              )}
              {product.price > 0 && (
                <p className='shop_category_product_details_wrapper_price'>
                  {toPriceWithCurrency(
                    Number(product.price),
                    list.restaurantInfo.currency
                  )}
                </p>
              )}
            </div>
            {!!product.options.length && (
              <div className='shop_category_product_details_extras'>
                {options.map((extra) => (
                  <div
                    className='shop_category_product_details_extras_extra'
                    key={extra.id}
                    onClick={() => onChangeExtraStatus(extra)}
                  >
                    <input
                      id={extra.id}
                      type={'checkbox'}
                      checked={extra.isActive}
                      onChange={() => onChangeExtraStatus(extra)}
                    />
                    <p className='shop_category_product_details_extras_extra_name'>
                      {extra.name}
                    </p>
                    <p className='shop_category_product_details_extras_extra_price'>
                      {toPriceWithCurrency(
                        Number(extra.price),
                        list.restaurantInfo.currency
                      )}
                    </p>
                  </div>
                ))}
              </div>
            )}
            <div className='shop_category_product_details_note'>
              <textarea
                id={'note'}
                value={note}
                maxLength={100}
                minLength={0}
                onFocus={() => (window.document.body.style.zoom = 1)}
                placeholder={t('shop.noteDescription')}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className='shop_category_product_details_footer'>
            <button
              onClick={() => {
                setShowProductDetailsPage(false);
                onAddItem({
                  ...product,
                  note: note,
                  options: options.filter(({ isActive }) => isActive),
                });
              }}
            >
              {t('shop.addProduct')}{' '}
              {toPriceWithCurrency(
                Number(totalAmount),
                list.restaurantInfo.currency
              )}
            </button>
          </div>
        </div>
      </SwipeableComponent>
      {hasOverlay && (
        <Overlay
          onClick={() => setShowProductDetailsPage(false)}
          isTransparent={true}
        />
      )}
    </>,
    document.getElementById('modal')
  );
}

ShopCategoryProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  setShowProductDetailsPage: PropTypes.func.isRequired,
  hasOverlay: PropTypes.bool,
};

export default memo(ShopCategoryProductDetails);
