import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import us from '../localizations/us.json';
import am from '../localizations/am.json';
import ru from '../localizations/ru.json';

i18n.use(initReactI18next).init({
  resources: { am, us, ru },
  lng: 'am',
  interpolation: { escapeValue: false },
});

export default i18n;
