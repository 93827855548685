import React, { memo } from 'react';
import { useSwipeable } from 'react-swipeable';

function SwipeableComponent({
  children,
  className,
  setter = () => {},
  setObject = () => {},
}) {
  const handlers = useSwipeable({
    onSwipedRight: () => {
      setter(false);
      setObject(null);
    },
  });

  return (
    <div className={className} {...handlers}>
      {children}
    </div>
  );
}

export default memo(SwipeableComponent);
