import { memo, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { IoIosArrowBack } from 'react-icons/io';
import { RiSearchLine } from 'react-icons/ri';
import SwipeableComponent from '../../../../../components/layout/swipeable';
import ShopCategoryProduct from '../../shop-categories/shop-category-product';
import Loading from '../../../../../components/loading';
import { useTranslation } from 'react-i18next';

function ShopHeaderSearch({
  isSearching,
  setSearch,
  setIsSearching,
  loading,
  products,
  search,
}) {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isSearching &&
        createPortal(
          <SwipeableComponent
            setter={() => {
              setSearch('');
              setIsSearching(false);
            }}
          >
            <div
              className={cn(
                'shop_header_in_search_modal',
                { 'shop_header_in_search_modal--open': isSearching },
                { 'shop_header_in_search_modal--dark': false }
              )}
            >
              <div className='shop_header_in_search_modal_wrapper'>
                <IoIosArrowBack
                  onClick={() => {
                    setSearch('');
                    setIsSearching(false);
                  }}
                />
                <div className='shop_header_in_search_modal_input'>
                  <RiSearchLine />
                  <input
                    type='text'
                    placeholder={t('shop.search')}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <div className='shop_header_in_search_modal_in'>
                {!loading && (
                  <ShopCategoryProduct
                    products={products}
                    showDetails={windowWidth <= 600}
                    isDarkMode={false}
                  />
                )}
                {!loading && search && !products.length && (
                  <div className='shop_header_in_search_modal_in'>
                    <div className='shop_header_in_search_modal_in_loading'>
                      <h2 className='shop_header_in_search_modal_in_title'>
                        {t('shop.searchNotFound')}
                      </h2>
                      <p className='shop_header_in_search_modal_in_description'>
                        {t('shop.searchLookForElse')}
                      </p>
                    </div>
                  </div>
                )}
                {!loading && !search && !products.length && (
                  <div className='shop_header_in_search_modal_in'>
                    <div className='shop_header_in_search_modal_in_loading'>
                      <h2 className='shop_header_in_search_modal_in_title'>
                        {t('shop.searchLooking')}
                      </h2>
                      <p className='shop_header_in_search_modal_in_description'>
                        {t('shop.searchLookingDescription')}
                      </p>
                    </div>
                  </div>
                )}
                {loading && (
                  <div
                    className={cn('shop_checkout_loading', {
                      'shop_checkout_loading--dark': false,
                    })}
                  >
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </SwipeableComponent>,
          document.getElementById('modal')
        )}
    </>
  );
}

export default memo(ShopHeaderSearch);
