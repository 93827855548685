import './index.scss';
import { memo, useContext, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import ShopPasscode from './shop-passcode';
import api from '../../utils/shop-api';
import Loading from '../../components/loading';
import PreferencesContext from '../../context/preferences/preferences-context';
import Overlay from '../../components/layout/overlay';
import io from 'socket.io-client';
import SocketContext from '../../context/socket/socket-context';
import { errToString } from '../../utils';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import UserContext from '../../context/user/user-context';
import sound from '../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function Shop() {
  const { setSocket } = useContext(SocketContext);
  const preferences = useContext(PreferencesContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const tableId = pathname.split('/')[4];
  const branchId = pathname.split('/')[2];
  const [isAuth, setIsAuth] = useState(null);
  const { setUser, user } = useContext(UserContext);

  useEffect(() => {
    api
      .get('/guests/token')
      .then(({ data }) => {
        setUser(data);
        setIsAuth(true);
      })
      .catch(() => setIsAuth(false));
  }, [setUser]);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    api
      .get(`shop/1/preferences`, {
        branchId: 1,
      })
      .then(({ data }) => {
        preferences.set(data);
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });

    // eslint-disable-next-line
  }, [t, isAuth, preferences.set, branchId]);

  useEffect(() => {
    if (!isAuth || !user) {
      return;
    }

    const socket = io(process.env.REACT_APP_BASE_URL, {
      transports: ['websocket'],
      auth: {
        guest: {
          id: user.id,
        },
      },
    });

    socket.on('connect', () => {
      setSocket(socket);
      console.log('connected');
    });
    socket.on('table:close', () => {
      localStorage.removeItem(`basket_${branchId}_${tableId}`);
      window.location.reload();
    });
    socket.on('disconnect', (reason) => {
      setSocket(null);
      console.log('disconnect', reason);
    });

    return () => {
      socket.off('connect');
      socket.off('table:close');
      socket.off('disconnect');
      setSocket(null);
    };
  }, [isAuth, setSocket, branchId, tableId, user]);
  useEffect(() => {
    const handleOrientationChange = () => {
      if (window.innerHeight < window.innerWidth) {
        document.documentElement.style.setProperty('--landscape-disabled', '1');
      } else {
        document.documentElement.style.removeProperty('--landscape-disabled');
      }
    };
    window.addEventListener('orientationchange', handleOrientationChange);

    const invoiceId = Number(window.location.search.split('invoice_id=')[1]);
    if (invoiceId) {
      api
        .post(`/shop/${branchId}/pay/complete`, {
          bank: 'ameria',
          invoiceId,
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [branchId]);

  if (isAuth === null) {
    return (
      <div
        className={cn('shop_checkout_loading', {
          'shop_checkout_loading--dark': false,
        })}
      >
        <Overlay isWhite={true} />
        <Loading />
      </div>
    );
  }

  return (
    <div className={cn('shop', { 'shop--dark': false })}>
      {!isAuth && <ShopPasscode tableId={tableId} setIsAuth={setIsAuth} />}
      {isAuth && <Outlet />}
    </div>
  );
}

export default memo(Shop);
