import './index.scss';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import { AiOutlineQrcode } from 'react-icons/ai';
import { IoMdClose, IoMdRefreshCircle } from 'react-icons/io';
import DropDown from '../../../components/drop-down';
import Warning from '../../../components/warning';
import Loading from '../../../components/loading';
import api from '../../../utils/api';
import sound from '../../../assets/sounds/notification.wav';
import { BiReset } from 'react-icons/bi';
import Areas from './areas';
import { LIMITS } from '../../../constants/general';
import Pagination from '../../../components/pagination';
import PassCodePopup from '../live-orders/passcode-popup';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function Tables() {
  const { t } = useTranslation();
  const [tableName, setTableName] = useState('');
  const [tableSize, setTableSize] = useState('');
  const [selectedAreaField, setSelectedAreaField] = useState(null);
  const [tableMode, setTableMode] = useState('tables');
  const [tables, setTables] = useState(null);
  const [areas, setAreas] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addNewTable, setAddNewTable] = useState(false);
  const [addNewArea, setAddNewArea] = useState(false);
  const [areaIsEditing, setAreaIsEditing] = useState(false);
  const [warning, setWarning] = useState(false);
  const [tableIsEditing, setTableIsEditing] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [tableCurrentPage, setTableCurrentPage] = useState(0);
  const [areaCurrentPage, setAreaCurrentPage] = useState(0);
  const [tablePagesCount, setTablePagesCount] = useState(null);
  const [areaPagesCount, setAreaPagesCount] = useState(null);
  const [tableOffset, setTableOffset] = useState(0);
  const [tableLimit, setTableLimit] = useState(LIMITS[0]);
  const [areaOffset, setAreaOffset] = useState(0);
  const [areaLimit, setAreaLimit] = useState(LIMITS[0]);
  const [showPasscode, setShowPasscode] = useState(false);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState(null);
  const [integrations, setIntegrations] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const TablesTitles = useMemo(
    () => [
      {
        name: t('components.tables.name'),
        size: 200,
      },
      {
        name: t('components.tables.branch'),
        size: 200,
      },
      {
        name: t('components.tables.area'),
        size: 200,
      },
      {},
    ],
    [t]
  );

  const onChangeField = useCallback((e, setter) => {
    setError(null);
    setter(e.target.value);
  }, []);
  const onChangeMode = useCallback(() => {
    setError(null);
    if (tableMode === 'tables') {
      setTableMode('areas');
    }
    if (tableMode === 'areas') {
      setTableMode('tables');
    }
  }, [tableMode]);
  const onQrDownload = useCallback((table) => {
    api
      .get(`/tables/${table.id}/download`, {
        responseType: 'blob',
      })
      .then((res) => {
        const filename = `${table.name}.zip`;
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, []);
  const onAddNew = useCallback(() => {
    setError(null);

    if (tableMode === 'tables') {
      setAddNewTable(true);
      setAddNewArea(false);
    }
    if (tableMode === 'areas') {
      setAddNewTable(false);
      setAddNewArea(true);
    }
  }, [tableMode]);
  const onCreateTable = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      if (!tableName) {
        return setError('Name cannot be empty!');
      }
      if (!selectedAreaField) {
        return setError('Area cannot be empty!');
      }

      const data = {
        name: tableName,
        areaId: selectedAreaField ? selectedAreaField.id : '',
        branchId: selectedAreaField ? selectedAreaField.branch.id : '',
      };

      if (tableSize) {
        data.size = tableSize;
      }

      api
        .post('/tables', data)
        .then(({ data }) => {
          setAddNewTable(false);
          setTableName('');
          setTableSize('');
          setSelectedAreaField(null);

          const audio = new Audio(sound);
          const info = t('notifications.tableCreated');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();

          return setTables([...tables, data]);
        })
        .catch((err) => setError(t(`errors.${errToString(err)}`)));
    },
    [tableName, selectedAreaField, tableSize, t, tables]
  );
  const onTableDelete = useCallback(() => {
    api
      .delete(`/tables/${selectedTable.id}`)
      .then(() => {
        setWarning(false);
        const audio = new Audio(sound);
        const info = `${selectedTable.name} ${t('notifications.tableDeleted')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        return setTables(
          tables.map((table) => {
            if (selectedTable.id === table.id) {
              return {
                ...table,
                deletedAt: new Date(),
              };
            }
            return table;
          })
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t, tables, selectedTable]);
  const onUnfreezeTable = useCallback(
    (table) => {
      api
        .put(`/tables/${table.id}`, {
          restore: true,
        })
        .then((res) => {
          const audio = new Audio(sound);
          const info = `${table.name} ${t('notifications.tableActivated')}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          return setTables(
            tables.map((_table) => {
              if (table.id === _table.id) {
                return {
                  ...res.data,
                  deletedAt: null,
                };
              }
              return _table;
            })
          );
        })
        .catch((err) => {
          console.log(t(`errors.${errToString(err)}`));
          alert(t(`errors.${errToString(err)}`));
        });
    },
    [t, tables, setTables]
  );
  const onChangeLimit = useCallback(
    (option) => {
      if (option.value === tableLimit.value) {
        return;
      }
      if (option.value === areaLimit.value) {
        return;
      }

      if (tableMode === 'tables') {
        setTableLimit(option);
        setTableOffset(0);
        setLoading(true);
        setTableCurrentPage(0);
      }
      if (tableMode !== 'tables') {
        setAreaLimit(option);
        setAreaOffset(0);
        setLoading(true);
        setAreaCurrentPage(0);
      }
    },
    [tableMode, areaLimit, tableLimit]
  );
  const onRefresh = useCallback(() => {
    const promises = [
      api.get(
        `/tables?offset=${tableOffset}&limit=${tableLimit.value}&branchId=${selectedBranch.id}`
      ),
      api.get(
        `/areas?offset=${areaOffset}&limit=${areaLimit.value}&branchId=${selectedBranch.id}`
      ),
    ];
    Promise.all(promises)
      .then((res) => {
        setTables(res[0].data.results);
        setTablePagesCount(res[0].data.pagesCount);
        setAreaPagesCount(res[1].data.pagesCount);
        setAreas(
          res[1].data.results.map((area) => {
            return {
              ...area,
              label: area.name,
              value: area.id,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        alert(t(`errors.${errToString(err)}`));
      });
  }, [t, tableOffset, tableLimit, areaOffset, areaLimit, selectedBranch]);

  useEffect(() => {
    api
      .get(`/branches`)
      .then(({ data }) => {
        setBranches(
          data.map((data) => ({
            ...data,
            value: data.id,
            label: data.name,
          }))
        );
        return setSelectedBranch(
          data.map((obj) => ({
            ...obj,
            label: obj.name,
            value: obj.id,
          }))[0]
        );
      })
      .catch((err) => console.log(t(`errors.${errToString(err)}`)));
  }, [t, tableOffset, tableLimit, areaOffset, areaLimit]);
  useEffect(() => {
    if (!selectedBranch) {
      setAreas([]);
      return setTables([]);
    }
    setLoading(true);

    const promises = [
      api.get(
        `/tables?offset=${tableOffset}&limit=${tableLimit.value}&branchId=${selectedBranch.id}`
      ),
      api.get(
        `/areas?offset=${areaOffset}&limit=${areaLimit.value}&branchId=${selectedBranch.id}`
      ),
    ];
    Promise.all(promises)
      .then((res) => {
        setTables(res[0].data.results);
        setTablePagesCount(res[0].data.pagesCount);
        setAreaPagesCount(res[1].data.pagesCount);
        setAreas(
          res[1].data.results.map((area) => {
            return {
              ...area,
              label: area.name,
              value: area.id,
            };
          })
        );
        setLoading(false);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setLoading(false);
      });
  }, [t, tableOffset, tableLimit, areaOffset, areaLimit, selectedBranch]);
  useEffect(() => {
    api
      .get('/restaurant/integrations')
      .then((res) => {
        setIntegrations(res.data);
      })
      .catch((err) => {
        errToString(err);
      });
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            {!areaIsEditing &&
              !tableIsEditing &&
              !addNewTable &&
              !addNewArea && (
                <>
                  <h2>
                    {tableMode === 'tables'
                      ? t('routes.tables')
                      : t('routes.areas')}
                    {tableMode === 'tables' && (
                      <IoMdRefreshCircle onClick={onRefresh} />
                    )}
                  </h2>
                  <div className='page_in_header_filter'>
                    {!!branches?.length && (
                      <DropDown
                        placeholder={t('routes.branches')}
                        disabled={branches?.length < 2 ? true : false}
                        options={branches.map((branch, index) => ({
                          option: branch,
                          el: <li key={index}>{branch.label}</li>,
                        }))}
                        value={selectedBranch}
                        onChange={(e) => setSelectedBranch(e)}
                        position='bottom'
                        loading={false}
                        showClose={false}
                      />
                    )}
                  </div>
                  {integrations && !integrations.length && (
                    <button
                      className='page_in_header_btn page_in_header_btn--add'
                      onClick={onAddNew}
                    >
                      {tableMode === 'tables'
                        ? t('general.addTable')
                        : t('general.addArea')}
                    </button>
                  )}
                  <button
                    className='page_in_header_btn page_in_header_btn--mode'
                    onClick={onChangeMode}
                  >
                    {tableMode === 'tables'
                      ? t('routes.areas')
                      : t('routes.tables')}
                  </button>
                </>
              )}
            {!areaIsEditing &&
              !tableIsEditing &&
              !addNewArea &&
              addNewTable && (
                <>
                  <h2>{t('general.addTable')}</h2>
                  <button
                    className='page_in_header_back'
                    onClick={() => {
                      setAddNewTable(false);
                      setTableName('');
                      setTableSize('');
                      setSelectedAreaField(null);
                    }}
                  >
                    {t('general.back')}
                  </button>
                </>
              )}
            {!areaIsEditing &&
              !tableIsEditing &&
              !addNewTable &&
              addNewArea && (
                <>
                  <h2>{t('general.addArea')}</h2>
                  <button
                    className='page_in_header_back'
                    onClick={() => setAddNewArea(false)}
                  >
                    {t('general.back')}
                  </button>
                </>
              )}
            {!addNewTable &&
              !addNewArea &&
              !tableIsEditing &&
              areaIsEditing && (
                <>
                  <h2>
                    {t('general.edit')} {selectedArea.name}
                  </h2>
                  <button
                    className='page_in_header_back'
                    onClick={() => setAreaIsEditing(false)}
                  >
                    {t('general.back')}
                  </button>
                </>
              )}
            {!addNewTable &&
              !addNewArea &&
              !areaIsEditing &&
              tableIsEditing && (
                <>
                  <h2>
                    {t('general.edit')} {selectedTable.name}
                  </h2>
                  <button
                    className='page_in_header_back'
                    onClick={() => setTableIsEditing(false)}
                  >
                    {t('general.back')}
                  </button>
                </>
              )}
          </div>
          {!loading && (
            <div className='page_in_content'>
              <>
                {!tableIsEditing &&
                  !areaIsEditing &&
                  !addNewTable &&
                  !addNewArea &&
                  tableMode === 'tables' &&
                  !!tables?.length &&
                  !tables[0].branch.deletedAt && (
                    <Table
                      route={false}
                      titles={TablesTitles}
                      rows={
                        tables.map((table) => ({
                          id: table.id,
                          table: <p>{table?.name}</p>,
                          branch: <p>{table?.branch?.name}</p>,
                          area: <p>{table?.area?.name}</p>,
                          actions: (
                            <div className='page_in_content_actions'>
                              {!table?.deletedAt && (
                                <>
                                  <button
                                    onClick={() => onQrDownload(table)}
                                    className='page_in_content_actions_btn page_in_content_actions_btn--qr'
                                  >
                                    <AiOutlineQrcode />
                                  </button>
                                  {/*{integrations && !integrations.length && (*/}
                                  {/*  <button*/}
                                  {/*    className='page_in_content_actions_btn page_in_content_actions_btn--edit'*/}
                                  {/*    onClick={() => {*/}
                                  {/*      setSelectedTable(table);*/}
                                  {/*      setAddNewTable(false);*/}
                                  {/*      setAddNewArea(false);*/}
                                  {/*      setWarning(false);*/}
                                  {/*      setTableIsEditing(true);*/}
                                  {/*    }}*/}
                                  {/*  >*/}
                                  {/*    <AiTwotoneEdit />*/}
                                  {/*    {t('general.edit')}*/}
                                  {/*  </button>*/}
                                  {/*)}*/}
                                </>
                              )}
                              {table?.passcode && (
                                <button
                                  onClick={() => {
                                    setShowPasscode(true);
                                    return setSelectedTable(table);
                                  }}
                                  className='page_in_content_actions_btn page_in_content_actions_btn--edit'
                                >
                                  {t('general.passcode')}
                                </button>
                              )}
                              {!table?.deletedAt &&
                                integrations &&
                                !integrations.length && (
                                  <button
                                    className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                                    onClick={() => {
                                      setSelectedTable(table);
                                      setAddNewTable(false);
                                      setAddNewArea(false);
                                      setTableIsEditing(false);
                                      setWarning(true);
                                    }}
                                  >
                                    <IoMdClose />
                                  </button>
                                )}
                              {table.deletedAt &&
                                integrations &&
                                !integrations.length && (
                                  <button
                                    className='page_in_content_actions_btn page_in_content_actions_btn--reset'
                                    onClick={() => onUnfreezeTable(table)}
                                  >
                                    <BiReset />
                                  </button>
                                )}
                            </div>
                          ),
                        })) || []
                      }
                      hiddenFields={['id']}
                      loading={false}
                    />
                  )}
                {!tableIsEditing &&
                  !areaIsEditing &&
                  !addNewTable &&
                  !addNewArea &&
                  tableMode === 'tables' &&
                  !!tables?.length &&
                  tables[0].branch.deletedAt && (
                    <NoData title={t('noData.branchIsDisabled')} />
                  )}
                {!tableIsEditing &&
                  !areaIsEditing &&
                  !addNewTable &&
                  !addNewArea &&
                  tableMode === 'tables' &&
                  !tables?.length && <NoData title={t('noData.tables')} />}
                {!tableIsEditing &&
                  !areaIsEditing &&
                  !addNewArea &&
                  addNewTable && (
                    <>
                      {!!areas?.length && (
                        <form className='form' onSubmit={onCreateTable}>
                          <div className='form_columns'>
                            <div className='form_column'>
                              <label htmlFor=''>
                                {t('components.tables.name')}
                              </label>
                              <input
                                type='text'
                                value={tableName}
                                maxLength={50}
                                placeholder={t('components.tables.name')}
                                onChange={(e) => onChangeField(e, setTableName)}
                              />
                            </div>
                            <div className='form_column'>
                              <label htmlFor=''>
                                {t('components.tables.size')}
                              </label>
                              <input
                                type='nunmber'
                                value={tableSize}
                                placeholder={t('components.tables.size')}
                                onChange={(e) => onChangeField(e, setTableSize)}
                              />
                            </div>
                          </div>
                          <div className='form_columns'>
                            <div className='form_column'>
                              <label htmlFor=''>
                                {t('components.tables.area')}
                              </label>
                              <DropDown
                                placeholder={t('components.tables.area')}
                                options={areas
                                  .filter(({ deletedAt }) => !deletedAt)
                                  .map((area, index) => ({
                                    option: area,
                                    el: <li key={index}>{area.label}</li>,
                                  }))}
                                value={selectedAreaField}
                                onChange={(e) => setSelectedAreaField(e)}
                                position='bottom'
                                loading={false}
                              />
                            </div>
                            <div className='form_column' />
                          </div>
                          <div className='form_error form_error--left'>
                            {error && (
                              <div className='form_error_name'>
                                <PiWarningOctagonDuotone />
                                <p>{error}</p>
                              </div>
                            )}
                          </div>
                          <div className='form_actions'>
                            <button
                              className='form_actions_btn'
                              type={'submit'}
                            >
                              {t('general.save')}
                            </button>
                          </div>
                        </form>
                      )}
                      {!areas?.length && (
                        <div className='menu_in_content_importing'>
                          <div className='menu_in_content_importing_title'>
                            <h2>{t('general.doNotHaveAreas')}</h2>
                            <h3>{t('general.pleaseCreateArea')}</h3>
                          </div>
                          <div className='tables_warning'>
                            <button
                              className='tables_warning_btn'
                              onClick={() => {
                                setAddNewTable(false);
                                setAddNewArea(true);
                              }}
                            >
                              {t('general.addArea')}
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                {/*{!addNewArea &&*/}
                {/*  !addNewTable &&*/}
                {/*  !areaIsEditing &&*/}
                {/*  tableIsEditing && (*/}
                {/*    <EditTable*/}
                {/*      setTableIsEditing={setTableIsEditing}*/}
                {/*      selectedTable={selectedTable}*/}
                {/*      tables={tables}*/}
                {/*      setTables={setTables}*/}
                {/*    />*/}
                {/*  )}*/}
                <Areas
                  tableMode={tableMode}
                  addNewArea={addNewArea}
                  addNewTable={addNewTable}
                  tableIsEditing={tableIsEditing}
                  setAddNewArea={setAddNewArea}
                  areaIsEditing={areaIsEditing}
                  setAreaIsEditing={setAreaIsEditing}
                  selectedArea={selectedArea}
                  setSelectedArea={setSelectedArea}
                  setAreas={setAreas}
                  areas={areas}
                  loading={loading}
                  onRefreshTables={onRefresh}
                  branches={branches}
                  integrations={integrations}
                />
                {tableMode === 'tables' &&
                  !!tablePagesCount &&
                  !addNewTable &&
                  !tables[0].branch.deletedAt &&
                  !tableIsEditing && (
                    <div className='page_in_pagination'>
                      <Pagination
                        pagesCount={tablePagesCount}
                        setOffset={setTableOffset}
                        limit={tableLimit}
                        limitPlaceholder={tableLimit.label}
                        limits={LIMITS}
                        currentPage={tableCurrentPage}
                        setCurrentPage={setTableCurrentPage}
                        onChange={onChangeLimit}
                      />
                    </div>
                  )}
                {tableMode !== 'tables' &&
                  !!areaPagesCount &&
                  !areaIsEditing &&
                  !addNewArea && (
                    <div className='page_in_pagination'>
                      <Pagination
                        pagesCount={areaPagesCount}
                        setOffset={setAreaOffset}
                        limit={areaLimit}
                        limitPlaceholder={areaLimit.label}
                        limits={LIMITS}
                        currentPage={areaCurrentPage}
                        setCurrentPage={setAreaCurrentPage}
                        onChange={onChangeLimit}
                      />
                    </div>
                  )}
              </>
              {warning && (
                <Warning
                  description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${selectedTable.name}`}
                  onCancel={() => setWarning(false)}
                  onSubmit={onTableDelete}
                />
              )}
              {showPasscode && selectedTable && (
                <PassCodePopup
                  passcode={selectedTable.passcode}
                  setShowPasscode={setShowPasscode}
                />
              )}
            </div>
          )}
          {loading && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Tables);
