export const removeDuplicates = (arr, key) => [
  ...new Map(arr.map((item) => [item[key], item])).values(),
];

export const createArrFromLength = (length) => {
  const loadingArray = [];

  for (let i = 0; i < length; i++) {
    loadingArray.push(i);
  }

  return loadingArray;
};

export const toPriceWithCurrency = (price, currency) => {
  const formatToCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    maximumSignificantDigits: 20,
  });

  return formatToCurrency.format(price);
};

export const errToString = (err) => {
  console.log(err);

  if (Array.isArray(err?.response?.data)) {
    const error = err.response.data[0];
    return `${error.path.join('.')}.${error.type}`;
  }
  if (err?.response?.data?.error) {
    return err.response.data.error;
  }

  return 'internal.server.error';
};
export const rgbaToHex = ({ r, g, b, a }) => {
  const red = Math.round(Number(r)).toString(16).padStart(2, '0');
  const green = Math.round(Number(g)).toString(16).padStart(2, '0');
  const blue = Math.round(Number(b)).toString(16).padStart(2, '0');
  const alpha = Math.round(Number(a) * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${red}${green}${blue}${alpha}`;
};

export function openDeepLink(deepLink, url) {
  const startTime = Date.now();
  const timeout = 5000; // 2 seconds (adjust as needed)

  window.location.replace(deepLink);

  setTimeout(() => {
    const elapsedTime = Date.now() - startTime;

    if (elapsedTime < timeout + 500) {
      window.location.href = url; // Redirect to fallback URL
    }
  }, timeout);
}
