import './index.scss';
import {
  memo,
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
  cloneElement,
} from 'react';
import { HiArrowSmDown } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createPortal } from 'react-dom';

function DropDown({
  value,
  position = 'bottom',
  className = '',
  disabled,
  options,
  onSearch,
  onChange,
  onReset = () => null,
  onInit = ({ methods }) => null,
  placeholder,
  maxHeight,
  label,
  loading,
  hasHover = true,
  showClose = true,
  theme = 'default',
}) {
  const ref = useRef();
  const popupRef = useRef();
  const inputRef = useRef();
  const [popupTop, setPopupTop] = useState('-9999px');
  const [popupLeft, setPopupLeft] = useState('-9999px');
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(value?.label || '');
  const [selected, setSelected] = useState(value);
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  const filteredOptions = useMemo(() => {
    if (search && !onSearch && !selected) {
      return options.filter((option) => {
        if (option?.option) {
          return option?.option?.label
            .toLowerCase()
            .includes(search.toLowerCase());
        }

        return option?.label.toLowerCase().includes(search.toLowerCase());
      });
    }

    return options;
  }, [options, search, onSearch, selected]);
  const maxWidth = (() => {
    if (ref?.current?.offsetHeight) {
      return ref.current?.offsetWidth;
    }

    return null;
  })();
  const onChangeSearch = useCallback(
    (e) => {
      setActiveOptionIndex(-1);
      setSearch(e.target.value);
      setSelected(null);
      onSearch && onSearch(e.target.value);
    },
    [onSearch]
  );
  const onSelect = useCallback(
    (option) => {
      setSelected(option);
      setSearch(option.label);
      setIsOpen(false);
      onChange(option);
      setActiveOptionIndex(
        filteredOptions.findIndex((_option) => _option.value === option.value)
      );
      inputRef.current.blur();
    },
    [onChange, filteredOptions, inputRef]
  );
  const onDeselect = useCallback(() => {
    setSelected(null);
    setSearch('');
    setIsOpen(false);
    onReset();
    setActiveOptionIndex(-1);
  }, [onReset]);
  const onKeyDown = useCallback(
    (e) => {
      if (selected && e.keyCode === 8) {
        onReset();
        setActiveOptionIndex(-1);
        return setSelected(null);
      }

      if (e.keyCode === 9) {
        setIsOpen(false);
      }
      if (e.keyCode === 38) {
        const index =
          activeOptionIndex > 0 ? activeOptionIndex - 1 : activeOptionIndex;
        setActiveOptionIndex(index);
        setIsOpen(true);
      }
      if (e.keyCode === 40) {
        const index =
          activeOptionIndex < filteredOptions.length - 1
            ? activeOptionIndex + 1
            : activeOptionIndex;
        setActiveOptionIndex(index);
        setIsOpen(true);
      }
      if (e.keyCode === 13) {
        if (filteredOptions[activeOptionIndex]?.option) {
          e.preventDefault();
          return onSelect(filteredOptions[activeOptionIndex].option);
        }
        if (filteredOptions[activeOptionIndex]) {
          e.preventDefault();
          return onSelect(filteredOptions[activeOptionIndex]);
        }

        onSelect(filteredOptions[activeOptionIndex]);
      }
      if (e.keyCode === 27) {
        setSearch('');
        setSelected(null);
        setIsOpen(false);
        onReset();
        setActiveOptionIndex(-1);
        document.activeElement.blur();
      }
    },
    [activeOptionIndex, filteredOptions, onSelect, onReset, selected]
  );
  const onCloseMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    if (ref.current && popupRef.current) {
      if (position === 'top') {
        setPopupTop(
          ref.current?.getBoundingClientRect().top -
            popupRef.current?.getBoundingClientRect().height -
            5
        );
      }
      if (position === 'bottom') {
        setPopupTop(
          ref.current?.getBoundingClientRect().top +
            ref.current?.getBoundingClientRect().height +
            5
        );
      }

      setPopupLeft(ref.current?.getBoundingClientRect().left);
    }
  }, [ref, popupRef, isOpen, position, filteredOptions]);
  useEffect(() => {
    setActiveOptionIndex(
      filteredOptions.findIndex((option) => {
        if (option?.option) {
          return option?.option?.label === selected?.label;
        }

        return option?.label === selected?.label;
      })
    );
  }, [filteredOptions, selected]);
  useEffect(() => {
    setSelected(value);
    setSearch('');
  }, [value]);
  useEffect(() => {
    const resetSearch = () => setSearch('');
    onInit({ resetSearch });
    // eslint-disable-next-line
  }, []);

  return (
    <div
      ref={ref}
      className={cn('drop_down', { 'drop_down--disabled': disabled })}
    >
      <div
        className={cn('drop_down_in', {
          drop_down_in_disable_hover: !hasHover,
          drop_down_in_pagination: theme === 'pagination',
          drop_down_in_lng: theme === 'lng',
        })}
      >
        <div className='drop_down_search'>
          <input
            ref={inputRef}
            className={cn('drop_down_input', {
              'drop_down_input--active': isOpen,
            })}
            autoComplete='off'
            id={placeholder}
            type='text'
            placeholder={placeholder}
            onChange={onChangeSearch}
            value={selected?.label || search}
            onKeyDown={onKeyDown}
            onFocus={() => setIsOpen(true)}
            onClick={() => setIsOpen(true)}
            disabled={disabled}
          />
        </div>
        {theme === 'lng' && (
          <div className='drop_down_img' onClick={() => setIsOpen(true)}>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${value.value}.svg`}
              width='48'
              alt={value.value}
            />
          </div>
        )}
        {(!selected || !showClose) && theme !== 'lng' && (
          <label
            className={cn('drop_down_label', {
              drop_down_label_pagination: theme === 'pagination',
            })}
            htmlFor={placeholder}
            onClick={() => setIsOpen(true)}
          >
            {!label && <HiArrowSmDown />}
          </label>
        )}
        {selected && showClose && theme !== 'pagination' && theme !== 'lng' && (
          <label
            className='drop_down_label'
            htmlFor='dropDown'
            onClick={onDeselect}
          >
            <IoMdClose />
          </label>
        )}
      </div>
      {isOpen &&
        createPortal(
          <div
            ref={popupRef}
            className={cn('drop_down_menu', className, {
              drop_down_menu_pagination: theme === 'pagination',
            })}
            style={{ top: popupTop, left: popupLeft, maxWidth, maxHeight }}
          >
            <ul>
              <>
                {!loading && !filteredOptions?.length && <p>Empty</p>}
                {!loading &&
                  filteredOptions.map((option, index) => {
                    if (option.el) {
                      return cloneElement(option.el, {
                        onClick: () => {
                          onSelect(option.option);
                        },
                        onMouseEnter: () => setActiveOptionIndex(index),
                        className: cn(
                          'drop_down_menu__item',
                          option.el.props?.className,
                          {
                            'drop_down_menu__item--active':
                              index === activeOptionIndex,
                          }
                        ),
                      });
                    }

                    return (
                      <li
                        key={index}
                        className={cn('drop_down_menu__item', {
                          'drop_down_menu__item--active':
                            index === activeOptionIndex,
                        })}
                        onClick={() => onSelect(option)}
                        onMouseEnter={() => setActiveOptionIndex(index)}
                      >
                        {option.label}
                      </li>
                    );
                  })}
              </>
            </ul>
          </div>,
          document.getElementById('modal')
        )}
      {isOpen &&
        createPortal(
          <div className='drop_down_overlay' onClick={onCloseMenu} />,
          document.getElementById('modal')
        )}
    </div>
  );
}

DropDown.propTypes = {
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  onSearch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onInit: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  position: PropTypes.string,
  maxHeight: PropTypes.number,
  label: PropTypes.string,
  hasHover: PropTypes.bool,
  loading: PropTypes.bool,
  showClose: PropTypes.bool,
  theme: PropTypes.string,
};

export default memo(DropDown);
