import { memo, useCallback, useContext, useState } from 'react';
import './index.scss';
import Wrapper from '../../../components/layout/wrapper';
import Loading from '../../../components/loading';
import { useTranslation } from 'react-i18next';
import api from '../../../utils/api';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { errToString } from '../../../utils';
import AuthContext from '../../../context/auth/auth-context';

function Reviews() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [highRatingUrl, setHighRatingUrl] = useState(
    user.restaurant.restaurantInfo.lowRatingUrl
  );
  const [lowRatingUrl, setLowRatingUrl] = useState(
    user.restaurant.restaurantInfo.highRatingUrl
  );

  const onSave = useCallback(
    (e) => {
      e.preventDefault();

      if (pending) {
        return;
      }

      setPending(true);

      const data = {
        address: user.restaurant.address,
        name: user.restaurant.name,
        restaurantInfo: {
          highRatingUrl:
            highRatingUrl || user.restaurant.restaurantInfo.highRatingUrl,
          lowRatingUrl:
            lowRatingUrl || user.restaurant.restaurantInfo.lowRatingUrl,
          currency: user.restaurant.restaurantInfo.currency,
          phoneNumber: user.restaurant.restaurantInfo.phoneNumber,
          serviceFee: user.restaurant.restaurantInfo.serviceFee,
          wifiPassword: user.restaurant.restaurantInfo.wifiPassword,
          wifiUsername: user.restaurant.restaurantInfo.wifiUsername,
        },
      };

      api
        .put(`/restaurant`, data)
        .then((res) => {
          const audio = new Audio(sound);
          const info = t('notifications.prefUpdate');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setPending(false);
        })
        .catch((err) => {
          setPending(false);
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [highRatingUrl, lowRatingUrl, pending, t, user]
  );

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.reviews')}</h2>
          </div>
          <div className='page_in_content'>
            <form className='form' onSubmit={onSave}>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>
                    {t('dashboard.reviews.lowRatingUrl')}
                  </label>
                  <input
                    type='text'
                    value={lowRatingUrl}
                    placeholder={t('dashboard.reviews.lowRatingUrl')}
                    onChange={(e) => setLowRatingUrl(e.target.value)}
                  />
                </div>
                <div className='form_column' />
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>
                    {t('dashboard.reviews.highRatingUrl')}
                  </label>
                  <input
                    type='text'
                    value={highRatingUrl}
                    placeholder={t('dashboard.reviews.highRatingUrl')}
                    onChange={(e) => setHighRatingUrl(e.target.value)}
                  />
                </div>
                <div className='form_column' />
              </div>
              <div className='form_actions'>
                {!pending && (
                  <button className='form_actions_btn' type={'submit'}>
                    {t('general.save')}
                  </button>
                )}
                {pending && (
                  <button
                    onClick={() => {}}
                    className='form_actions_btn form_actions_btn--loading'
                  >
                    <Loading />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Reviews);
