import './index.scss';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Logo from '../../../../assets/logo/QRrobo version 1 - white.png';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FaCartShopping } from 'react-icons/fa6';
import { BsCreditCard2BackFill } from 'react-icons/bs';
import { TbLogout } from 'react-icons/tb';
import { IoIosArrowForward } from 'react-icons/io';
import { IoMdClose } from 'react-icons/io';
import Loading from '../../../../components/loading';
import api from '../../../../utils/shop-api';
import { errToString, toPriceWithCurrency } from '../../../../utils';
import NoData from '../../../../components/no-data';
import moment from 'moment';
import PreferencesContext from '../../../../context/preferences/preferences-context';
import UserContext from '../../../../context/user/user-context';

function ShopAccount({ setShow, branchId, lngId }) {
  const { list } = useContext(PreferencesContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [page, setPage] = useState('general');
  const [showInvoiceOrders, setShowInvoiceOrders] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const guestName = useMemo(() => {
    if (user) {
      return user.customer.name;
    } else {
      return '';
    }
  }, [user]);
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState(null);

  const onLogout = useCallback(() => {
    api
      .post('guests/logout')
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        alert(errToString(err));
        console.log(err);
      });
  }, []);
  useEffect(() => {
    setLoading(true);

    api
      .get(`/shop/orders/${branchId}?languageId=${lngId}`)
      .then((res) => {
        setInvoices(res.data.invoices);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err));
      });
  }, [branchId, lngId]);

  return (
    <div className='shop_account'>
      <div
        className={cn('shop_account_header', {
          'shop_account_header--dark': false,
        })}
      >
        <img src={Logo} alt='qrrobo' />
        <button
          onClick={() => {
            setShow(false);
          }}
        >
          {t('general.back')}
        </button>
      </div>
      <div
        className={cn('shop_account_form shop_account_form--active', {
          'shop_account_form--dark': false,
        })}
      >
        {page === 'general' && (
          <>
            <p className='shop_account_form_title'>{t('general.welcome')}</p>
            <p className='shop_account_form_description'>{guestName}</p>
            <div className='shop_account_form_in'>
              <ul className='shop_account_form_in_list'>
                <li
                  className='shop_account_form_in_list_item'
                  onClick={() => setPage('orders')}
                >
                  <FaCartShopping />
                  {t('general.myOrders')}
                  <span>
                    <IoIosArrowForward />
                  </span>
                </li>
                <li
                  className='shop_account_form_in_list_item'
                  onClick={() => setPage('payment_methods')}
                >
                  <BsCreditCard2BackFill />
                  {t('general.myPayments')}
                  <span>
                    <IoIosArrowForward />
                  </span>
                </li>
                <div
                  className='shop_account_form_in_list_logout'
                  onClick={onLogout}
                >
                  <TbLogout />
                  {t('general.logout')}
                </div>
              </ul>
            </div>
          </>
        )}
        {page === 'orders' && (
          <>
            {showInvoiceOrders === null && (
              <p className='shop_account_form_title'>
                <span onClick={() => setPage('general')}>
                  <IoMdClose />
                </span>
                {t('general.invoices')}
              </p>
            )}
            {showInvoiceOrders !== null && (
              <p className='shop_account_form_title'>
                <span
                  onClick={() => {
                    setShowInvoiceOrders(null);
                    setSelectedInvoice(null);
                  }}
                >
                  <IoMdClose />
                </span>
                {t('general.orders')}
              </p>
            )}
            <div className='shop_account_form_in'>
              <div className='shop_account_form_in_list'>
                {!loading && invoices && (
                  <>
                    {!!invoices.length &&
                      !selectedInvoice &&
                      invoices
                        .sort((a, b) => b.id - a.id)
                        .map((invoice, index) => (
                          <div
                            key={index}
                            className='shop_account_form_in_list_orders'
                          >
                            <div
                              className='shop_account_form_in_list_orders_header'
                              onClick={() => {
                                setShowInvoiceOrders(invoice.id);
                                setSelectedInvoice(invoice);
                              }}
                            >
                              {showInvoiceOrders === null && (
                                <>
                                  <div className='shop_account_form_in_list_orders_header_id'>
                                    invoice #{invoice.id}
                                    <span className='shop_account_form_in_list_orders_header_date'>
                                      {moment(invoice.createdAt).format(
                                        'DD/MM/YYYY HH:mm'
                                      )}
                                    </span>
                                  </div>
                                  <p className='shop_account_form_in_list_orders_header_price'>
                                    {toPriceWithCurrency(
                                      invoice.orders.reduce(
                                        (acc, order) =>
                                          acc + order.price * order.quantity,
                                        0
                                      ),
                                      list.restaurantInfo.currency
                                    )}
                                  </p>
                                  <span className='shop_account_form_in_list_orders_header_icon'>
                                    <IoIosArrowForward />
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    {selectedInvoice &&
                      showInvoiceOrders === selectedInvoice.id && (
                        <div className='shop_account_form_in_list_orders shop_account_form_in_list_orders--no_hover'>
                          {selectedInvoice.orders.map((order, index) => (
                            <div
                              className='shop_account_form_in_list_orders_header'
                              style={{ cursor: 'default' }}
                              key={index}
                            >
                              <div className='shop_account_form_in_list_orders_header_id'>
                                order #{order.id}
                                <span className='shop_account_form_in_list_orders_header_date'>
                                  {moment(order.createdAt).format(
                                    'DD/MM/YYYY HH:mm'
                                  )}
                                </span>
                              </div>
                              <p className='shop_account_form_in_list_orders_header_price'>
                                {toPriceWithCurrency(
                                  Number(order.price * order.quantity),
                                  list.restaurantInfo.currency
                                )}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    {!invoices.length && <NoData title={t('noData.orders')} />}
                  </>
                )}
                {loading && <Loading />}
              </div>
            </div>
          </>
        )}
        {page === 'payment_methods' && (
          <>
            <p className='shop_account_form_title'>
              <span onClick={() => setPage('general')}>
                <IoMdClose />
              </span>
              {t('general.paymentMethods')}
            </p>
            <div className='shop_account_form_in'>
              <div className='shop_account_form_in_list'>
                <NoData title={t('noData.payments')} />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default memo(ShopAccount);
