import './index.scss';
import { memo } from 'react';
import Wrapper from '../../../components/layout/wrapper';

function Analytics() {
  return (
    <Wrapper>
      <div className='analytics'></div>
    </Wrapper>
  );
}

export default memo(Analytics);
