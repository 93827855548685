import './index.scss';
import { memo, useContext } from 'react';
import { GrFormAdd } from 'react-icons/gr';
import { toPriceWithCurrency } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../../../context/auth/auth-context';

function TerminalProduct({
  categoryProducts,
  onAddItem,
  setSelectedProduct,
  setShowProductDetailsPage,
}) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  return categoryProducts.map((product, index) => (
    <li
      key={index}
      className='terminal_product'
      onClick={(e) => {
        e.stopPropagation();
        if (!product.options.length) {
          return onAddItem({
            ...product,
            options: [],
          });
        }
        setSelectedProduct(product);
        return setShowProductDetailsPage(true);
      }}
    >
      <div className='terminal_product_wrapper'>
        <div className='terminal_product_options'>
          <span>{t('dashboard.terminal.name')}:</span>
          <p className='terminal_product_options_name'>{product.name}</p>
        </div>
      </div>
      <div className='terminal_product_actions'>
        <button className='terminal_product_actions_btn terminal_product_actions_btn--add'>
          <GrFormAdd />
        </button>
        <div className='terminal_product_options'>
          <span>{t('dashboard.terminal.price')}:</span>
          <p className='terminal_product_options_price'>
            {toPriceWithCurrency(
              Number(product.price),
              user.restaurant.restaurantInfo.currency
            )}
          </p>
        </div>
      </div>
    </li>
  ));
}

export default memo(TerminalProduct);
