import './index.scss';
import { memo, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { toPriceWithCurrency } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { PiDotsSixVerticalBold } from 'react-icons/pi';
import CategoryProductDetails from './category-product-details';
import AuthContext from '../../../../../context/auth/auth-context';

function CategoryProduct({
  isActive,
  name,
  price,
  id,
  selectedLngId,
  selectedLng,
  onRefresh,
  hasDefaultIntegration,
  index,
  products,
  setProducts,
  setDropCategoryTargetIndex,
  primaryLngId,
  type = 'default',
  labels,
  category,
}) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [dropTargetIndex, setDropTargetIndex] = useState(null);
  const [dragSourceIndex, setDragSourceIndex] = useState(null);

  const handleDragStart = useCallback((e, index) => {
    e.stopPropagation();

    e.dataTransfer.setData('text/plain', index);
    setDragSourceIndex(index);
  }, []);
  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDropCategoryTargetIndex(null);
      setDropTargetIndex(index);
    },
    [setDropCategoryTargetIndex, index]
  );
  const handleDragLeave = useCallback(
    (e) => {
      e.stopPropagation();
      setDropCategoryTargetIndex(null);
      setDropTargetIndex(null);
    },
    [setDropCategoryTargetIndex]
  );
  const handleDrop = useCallback(
    (e, targetIndex) => {
      e.preventDefault();
      e.stopPropagation();

      setDropTargetIndex(null);

      const sourceIndex = e.dataTransfer.getData('text/plain');
      const newObjects = [...products];

      const [draggedObject] = newObjects.splice(sourceIndex, 1);
      newObjects.splice(targetIndex, 0, draggedObject);
      setProducts(newObjects);
    },
    [products, setProducts]
  );
  const handleDragEnd = useCallback((e) => {
    e.stopPropagation();

    setDragSourceIndex(null);
  }, []);

  return (
    <>
      {type === 'default' && (
        <div className='category_product'>
          <div
            className={cn('category_product_in', {
              'category_product_in--freezed': !isActive,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setShowProductDetails(true);
            }}
          >
            <h2 className='category_product_in_name'>{name}</h2>
            <div className='category_product_in_wrapper'>
              {!!Number(price) && (
                <div className='category_product_in_price'>
                  {toPriceWithCurrency(
                    Number(price),
                    user.restaurant.restaurantInfo.currency
                  )}
                </div>
              )}
              <p
                className={cn(
                  'category_product_in_status',
                  { 'category_product_in_status--active': isActive },
                  { 'category_product_in_status--disable': !isActive }
                )}
              >
                {isActive ? t('general.available') : t('general.disable')}
              </p>
            </div>
          </div>
          {showProductDetails && (
            <CategoryProductDetails
              branchId={category.branchId}
              categoryItemId={id}
              lng={selectedLng}
              lngId={selectedLngId}
              setShowProductDetails={setShowProductDetails}
              onRefresh={onRefresh}
              primaryLngId={primaryLngId}
              hasDefaultIntegration={hasDefaultIntegration}
              labels={labels.map((label) => ({
                ...label,
                label: label.name,
                value: label.id,
              }))}
            />
          )}
        </div>
      )}
      {type !== 'default' && (
        <div
          draggable
          className={cn(
            'category_product',
            { 'category_product--highlighted': index === dropTargetIndex },
            { 'category_product--dragging': index === dragSourceIndex }
          )}
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e)}
          onDragLeave={(e) => handleDragLeave(e)}
          onDragEnd={(e) => handleDragEnd(e)}
          onDrop={(e) => handleDrop(e, index)}
        >
          <div className='category_product_reorder' style={{ cursor: 'grab' }}>
            <PiDotsSixVerticalBold />
          </div>
          <div
            className={cn('category_product_in', {
              'category_product_in--freezed': !isActive,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setShowProductDetails(true);
            }}
          >
            <h2 className='category_product_in_name'>{name}</h2>
            <div className='category_product_in_wrapper'>
              {!!Number(price) > 0 && (
                <div className='category_product_in_price'>
                  {toPriceWithCurrency(
                    Number(price),
                    user.restaurant.restaurantInfo.currency
                  )}
                </div>
              )}
              <p
                className={cn(
                  'category_product_in_status',
                  { 'category_product_in_status--active': isActive },
                  { 'category_product_in_status--disable': !isActive }
                )}
              >
                {isActive ? t('general.available') : t('general.disable')}
              </p>
            </div>
          </div>
          {showProductDetails && (
            <CategoryProductDetails
              branchId={category.branchId}
              categoryItemId={id}
              lng={selectedLng}
              lngId={selectedLngId}
              setShowProductDetails={setShowProductDetails}
              onRefresh={onRefresh}
              primaryLngId={primaryLngId}
              hasDefaultIntegration={hasDefaultIntegration}
              labels={labels.map((label) => ({
                ...label,
                label: label.name,
                value: label.id,
              }))}
            />
          )}
        </div>
      )}
    </>
  );
}

CategoryProduct.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  categoryName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  selectedLngId: PropTypes.number.isRequired,
  selectedLng: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  hasDefaultIntegration: PropTypes.object,
};

export default memo(CategoryProduct);
