import React, { memo } from 'react';
import './index.scss';
import ReactFlagsSelect from 'react-flags-select';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

function LanguageDropDown({
  selected,
  setSelected,
  searchable,
  className,
  countries,
}) {
  const { t } = useTranslation();

  return (
    <div className={cn('language_drop_down', className)}>
      <ReactFlagsSelect
        searchable={searchable}
        selected={selected}
        onSelect={(code) => setSelected(code)}
        placeholder={t('general.createLanguage')}
        countries={countries}
        customLabels={{
          AM: 'Armenian',
          RU: 'Russian',
          US: 'English',
          ES: 'Spanish',
          FR: 'French',
          DE: 'German',
        }}
      />
    </div>
  );
}

export default memo(LanguageDropDown);
