import './index.scss';
import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import api from '../../../../../utils/api';
import { errToString } from '../../../../../utils';
import Overlay from '../../../../../components/layout/overlay';
import { CiImageOn } from 'react-icons/ci';
import sound from '../../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';

function AddCategoryProduct({
  selectedCategory,
  lngId,
  lng,
  setAddProductFormIsOpen,
  onRefresh,
  branchId,
}) {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [src, setSrc] = useState('');
  const [isActive, setIsActive] = useState(true);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
  }, []);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      const formData = new FormData();
      formData.append('name', name);
      formData.append('price', price);
      formData.append('isActive', isActive);
      formData.append('categoryId', selectedCategory.id);
      formData.append('optionSelectionType', 'single');

      if (description) {
        formData.append('description', description);
      }
      if (src) {
        formData.append('image', src);
      }

      api
        .post(`products?languageId=${lngId}&branchId=${branchId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          setAddProductFormIsOpen(false);
          onRefresh();
        })
        .catch((err) => {
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [
      branchId,
      onRefresh,
      setAddProductFormIsOpen,
      t,
      lngId,
      isActive,
      name,
      price,
      description,
      src,
      selectedCategory,
    ]
  );

  return (
    <>
      {createPortal(
        <div
          className='add_category_product add_category_product--active'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='add_category_product_in'>
            <div className='add_category_product_in_wrapper'>
              <h2 className='add_category_product_in_title'>
                {t('general.addProduct')} {lng?.toUpperCase()}
              </h2>
              <img
                src={`https://hatscripts.github.io/circle-flags/flags/${lng?.toLowerCase()}.svg`}
                alt={lng}
              />
            </div>
            <p className='add_category_product_in_name'>
              {selectedCategory.label}
            </p>
            <form className='form'>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('dashboard.menu.category')}</label>
                  <input
                    name='product_name'
                    type='text'
                    value={selectedCategory.label}
                    placeholder={t('dashboard.menu.category')}
                    onChange={(e) => onChangeField(e, setName)}
                    disabled={true}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor='product_name'>
                    {t('dashboard.menu.name')}
                  </label>
                  <input
                    name='product_name'
                    type='text'
                    value={name}
                    placeholder={t('dashboard.menu.name')}
                    onChange={(e) => onChangeField(e, setName)}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor='product_description'>
                    {t('dashboard.menu.description')}
                  </label>
                  <textarea
                    name='product_description'
                    value={description}
                    placeholder={t('dashboard.menu.description')}
                    onChange={(e) => onChangeField(e, setDescription)}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor='product_price'>
                    {t('dashboard.menu.price')}
                  </label>
                  <input
                    name='product_price'
                    type='text'
                    value={price}
                    placeholder={t('dashboard.menu.price')}
                    onChange={(e) => onChangeField(e, setPrice)}
                    disabled={false}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>
                    {t('dashboard.menu.productImage')} (200x200px)
                  </label>
                  {src && (
                    <>
                      <div className='form_column_img'>
                        <img alt='not found' src={URL.createObjectURL(src)} />
                      </div>
                      <label
                        className='form_file_btn'
                        onClick={() => {
                          setSrc(null);
                        }}
                      >
                        {t('general.remove')}
                      </label>
                    </>
                  )}
                  {!src && (
                    <>
                      <div className='form_column_img'>
                        <CiImageOn />
                      </div>
                      <label className='form_file_btn' htmlFor='file'>
                        {t('general.edit')}
                      </label>
                      <input
                        id='file'
                        type='file'
                        name='partnerSrc'
                        onChange={(event) => {
                          setSrc(event.target.files[0]);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <div className='form_column_radio'>
                    <label htmlFor=''>
                      {t('dashboard.menu.productAvailable')}
                    </label>
                    <div className='switcher'>
                      <input
                        type='checkbox'
                        onChange={() => setIsActive(!isActive)}
                        checked={isActive}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='add_category_product_footer'>
            <button
              className='add_category_product_footer_btn add_category_product_footer_btn--cancel'
              onClick={(e) => {
                e.stopPropagation();
                setAddProductFormIsOpen(false);
              }}
            >
              {t('general.cancel')}
            </button>
            <button
              className='add_category_product_footer_btn add_category_product_footer_btn--submit'
              onClick={onSubmit}
            >
              {t('general.save')}
            </button>
          </div>
        </div>,
        document.getElementById('modal')
      )}
      <Overlay
        onClick={(e) => {
          e.stopPropagation();
          setAddProductFormIsOpen(false);
        }}
      />
    </>
  );
}

export default memo(AddCategoryProduct);
