import './index.scss';
import { memo, useCallback, useContext, useState } from 'react';
import Logo from '../../../../assets/logo/QRrobo version 1 - white.png';
import Gmail from '../../../../assets/icons/gmail.svg';
import Email from '../../../../assets/icons/email.svg';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useGoogleLogin } from '@react-oauth/google';
import { FaArrowCircleRight } from 'react-icons/fa';
import api from '../../../../utils/shop-api';
import { errToString } from '../../../../utils';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import UserContext from '../../../../context/user/user-context';

function ShopSignUp({ setShow, invoiceId }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setUser } = useContext(UserContext);

  const [isOtherEmailActive, setIsOtherEmailActive] = useState(false);
  const [otherEmailPage, setOtherEmailPage] = useState('email');
  const [otherEmail, setOtherEmail] = useState('');
  const [otherEmailName, setOtherEmailName] = useState('');
  const [otherEmailCode, setOtherEmailCode] = useState('');
  const tableId = pathname.split('/')[4];

  const onGmailSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const data = {
        accessToken: tokenResponse.access_token,
        tableId: tableId,
        invoiceId: invoiceId,
      };

      api
        .post('/guests/google', data)
        .then(({ data }) => {
          setUser(data.guest);
          setShow(false);
        })
        .catch((err) => {
          console.log(errToString(err));
          alert(errToString(err));
        });
    },
    onError: (err) => {
      alert(err);
      console.log(err);
    },
    onNonOAuthError: (err) => {
      alert(err);
      console.log(err);
    },
  });
  const onChangeOtherEmailPage = useCallback(() => {
    if (otherEmailPage === 'email') {
      setOtherEmailPage('email');
      setOtherEmail('');
      setOtherEmailName('');
      setOtherEmailCode('');
      return setIsOtherEmailActive(false);
    }
    if (otherEmailPage === 'name') {
      return setOtherEmailPage('email');
    }
    if (otherEmailPage === 'code') {
      return setOtherEmailPage('name');
    }
  }, [otherEmailPage]);
  const onEmailSignUp = useCallback(() => {
    const data = {
      name: otherEmailName,
      email: otherEmail,
      tableId: tableId,
      invoiceId: invoiceId,
    };

    api
      .post('/guests/email', data)
      .then(({ data }) => {
        console.log(data);
        setOtherEmailPage('code');
        const audio = new Audio(sound);
        const info = `Verification code has just been send to ${otherEmail}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
      })
      .catch((err) => {
        setOtherEmailPage('email');
        console.log(errToString(err));
        alert(errToString(err));
      });
  }, [tableId, otherEmail, otherEmailName, invoiceId]);
  const onEmailSignUpVerify = useCallback(() => {
    const data = {
      code: otherEmailCode,
    };

    api
      .post('/guests/email/verify', data)
      .then(({ data }) => {
        setUser(data.guest);
        setIsOtherEmailActive(false);
        setOtherEmail('');
        setOtherEmailName('');
        setOtherEmailCode('');
        setShow(false);
      })
      .catch((err) => {
        setOtherEmailCode('');
        const audio = new Audio(sound);
        const info = `Something went wrong, please try again`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
        console.log(errToString(err));
        alert(errToString(err));
      });
  }, [setUser, otherEmailCode, setShow]);

  return (
    <div className='shop_sign_up'>
      <div
        className={cn('shop_sign_up_header', {
          'shop_sign_up_header--dark': false,
        })}
      >
        <img src={Logo} alt='qrrobo' />
        {!isOtherEmailActive && (
          <button onClick={() => setShow(false)}>{t('general.skip')}</button>
        )}
      </div>
      <div
        className={cn('shop_sign_up_form shop_sign_up_form--active', {
          'shop_sign_up_form--dark': false,
        })}
      >
        <p className='shop_sign_up_form_title'>{t('general.welcome')}</p>
        <p className='shop_sign_up_form_description'>
          {t('general.letsStartSigning')}
        </p>
        <div className='shop_sign_up_form_in'>
          {!isOtherEmailActive && (
            <>
              <div
                className='shop_sign_up_form_in_btn'
                onClick={() => onGmailSignUp()}
              >
                <img src={Gmail} alt='gmail' />
                Google
              </div>
              <div
                className='shop_sign_up_form_in_btn'
                onClick={() => setIsOtherEmailActive(true)}
              >
                <img src={Email} alt='email' />
                Email
              </div>
            </>
          )}
          {isOtherEmailActive && (
            <>
              {otherEmailPage === 'email' && (
                <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                  <input
                    className='shop_sign_up_form_in_btn_input'
                    type='text'
                    value={otherEmail}
                    placeholder={t('general.email')}
                    onChange={(e) => setOtherEmail(e.target.value)}
                  />
                  <div
                    className='shop_sign_up_form_in_btn_icon'
                    onClick={() => setOtherEmailPage('name')}
                  >
                    <FaArrowCircleRight />
                  </div>
                </div>
              )}
              {otherEmailPage === 'name' && (
                <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                  <input
                    className='shop_sign_up_form_in_btn_input'
                    type='text'
                    value={otherEmailName}
                    placeholder={t('general.name')}
                    onChange={(e) => setOtherEmailName(e.target.value)}
                  />
                  <div
                    className='shop_sign_up_form_in_btn_icon'
                    onClick={onEmailSignUp}
                  >
                    <FaArrowCircleRight />
                  </div>
                </div>
              )}
              {otherEmailPage === 'code' && (
                <div className='shop_sign_up_form_in_btn shop_sign_up_form_in_btn--nohover'>
                  <input
                    className='shop_sign_up_form_in_btn_input'
                    type='text'
                    value={otherEmailCode}
                    placeholder={t('general.code')}
                    onChange={(e) => setOtherEmailCode(e.target.value)}
                  />
                  <div
                    className='shop_sign_up_form_in_btn_icon'
                    onClick={onEmailSignUpVerify}
                  >
                    <FaArrowCircleRight />
                  </div>
                </div>
              )}
              <div
                className='shop_sign_up_form_in_btn_back'
                onClick={onChangeOtherEmailPage}
              >
                {t('general.back')}
              </div>
            </>
          )}
        </div>
        <div className='shop_sign_up_form_footer'>{t('general.shopTerms')}</div>
      </div>
    </div>
  );
}

export default memo(ShopSignUp);
