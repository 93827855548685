import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import api from '../../../../../../utils/api';
import Loading from '../../../../../../components/loading';
import Table from '../../../../../../components/table';
import EditProductExtra from './edit-product-extra';
import NoData from '../../../../../../components/no-data';
import { useTranslation } from 'react-i18next';
import {
  errToString,
  removeDuplicates,
  toPriceWithCurrency,
} from '../../../../../../utils';
import { createPortal } from 'react-dom';
import Overlay from '../../../../../../components/layout/overlay';
import { FiEdit } from 'react-icons/fi';
import AddProductExtra from './add-product-extra';
import DropDown from '../../../../../../components/drop-down';
import { CiSquareRemove } from 'react-icons/ci';
import sound from '../../../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import AuthContext from '../../../../../../context/auth/auth-context';

function CategoryProductDetails({
  categoryItemId,
  lngId,
  lng,
  setShowProductDetails,
  onRefresh,
  hasDefaultIntegration,
  primaryLngId,
  labels,
  branchId,
}) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [src, setSrc] = useState('');
  const [isProductActive, setIsProductActive] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editProductExtra, setEditProductExtra] = useState(false);
  const [addProductExtra, setAddProductExtra] = useState(false);
  const [selectedExtra, setSelectedExtra] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);

  const extrasTitles = useMemo(
    () => [
      {
        name: t('components.tables.name'),
        size: 200,
      },
      {
        name: t('components.tables.price'),
      },
      {
        name: 'min',
      },
      {
        name: 'max',
      },
      {},
    ],
    [t]
  );

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
  }, []);
  const onChangeExtraActivity = useCallback(
    (extra, type) => {
      if (type === 'restore') {
        const data = {
          restore: true,
        };

        api
          .put(`/products/options/${extra.id}?languageId=${primaryLngId}`, data)
          .then(({ data }) => {
            return setSelectedProduct({
              ...selectedProduct,
              options: selectedProduct.options.map((option) => {
                if (option.id === data.id) {
                  return {
                    ...option,
                    deletedAt: null,
                  };
                }
                return option;
              }),
            });
          })
          .catch((err) => {
            console.log(errToString(err));
            const audio = new Audio(sound);
            const info = `${errToString(err)}`;
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              type: 'error',
            });
            audio.play();
            console.log(errToString(err));
          });
      }
      if (type === 'delete') {
        api
          .delete(`/products/options/${extra.id}`)
          .then(({ data }) => {
            return setSelectedProduct({
              ...selectedProduct,
              options: selectedProduct.options.map((option) => {
                if (option.id === data.id) {
                  return {
                    ...option,
                    deletedAt: new Date(),
                  };
                }
                return option;
              }),
            });
          })
          .catch((err) => {
            console.log(t(`errors.${errToString(err)}`));
            const audio = new Audio(sound);
            const info = `${errToString(err)}`;
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              type: 'error',
            });
            audio.play();
            console.log(errToString(err));
          });
      }
    },
    [primaryLngId, selectedProduct, t]
  );
  const onChangeProductActivity = useCallback(
    (id, type) => {
      if (type === 'restore') {
        const data = {
          restore: true,
        };

        api
          .put(`/products/${id}?languageId=${primaryLngId}`, data)
          .then(() => {
            setIsProductActive(true);
          })
          .catch((err) => {
            console.log(errToString(err));
            const audio = new Audio(sound);
            const info = `${errToString(err)}`;
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              type: 'error',
            });
            audio.play();
            console.log(errToString(err));
          });
      }
      if (type === 'delete') {
        api
          .delete(`/products/${id}`)
          .then(() => {
            setIsProductActive(false);
          })
          .catch((err) => {
            console.log(errToString(err));
            const audio = new Audio(sound);
            const info = `${errToString(err)}`;
            toast.info(info, {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
              type: 'error',
            });
            audio.play();
            console.log(errToString(err));
          });
      }
    },
    [primaryLngId]
  );
  const onRemoveLabel = useCallback(
    (label) => {
      return setSelectedLabels(
        selectedLabels.filter(({ id }) => id !== label.id)
      );
    },
    [selectedLabels]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData();
      formData.append('name', name);
      formData.append('price', price);

      if (description) {
        formData.append('description', description);
      }
      if (src) {
        formData.append('image', src);
      }
      if (!!selectedLabels?.length) {
        formData.append('labelIds', [
          ...selectedLabels.map((label) => label.id),
        ]);
      }

      api
        .put(`products/${selectedProduct.id}?languageId=${lngId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          setShowProductDetails(false);
          setLoading(false);
          onRefresh();
        })
        .catch((err) => {
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = errToString(`errors.${err}`);
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          setLoading(false);
        });
    },
    [
      selectedLabels,
      onRefresh,
      setShowProductDetails,
      t,
      lngId,
      selectedProduct,
      name,
      price,
      description,
      src,
    ]
  );

  useEffect(() => {
    Promise.all([
      api.get(`/products/${categoryItemId}?languageId=${lngId}&freezed`),
      api.get(
        `/product-categories?languageId=${lngId}&freezed&branchId=${branchId}`
      ),
    ])
      .then((res) => {
        setLoading(false);
        setSelectedProduct(res[0].data);
        setName(res[0].data.name);
        setDescription(res[0].data.description);
        setPrice(res[0].data.price);
        setIsProductActive(res[0].data.deletedAt ? false : true);
        setSelectedLabels(
          res[0].data.labels.map((label) => ({
            ...label,
            value: label.id,
            label: label.name,
          }))
        );

        const category = res[1].data.find(
          (category) => category.id === res[0].data.categoryId
        );
        setSelectedCategory(category.name);
      })
      .catch((err) => console.log(t(`errors.${errToString(err)}`)));
  }, [t, categoryItemId, lngId, branchId]);

  return (
    <>
      {createPortal(
        <div
          className='category_product_details category_product_details--active'
          onClick={(e) => e.stopPropagation()}
        >
          {!loading && (
            <>
              <div className='category_product_details_in'>
                <div className='category_product_details_in_wrapper'>
                  <h2 className='category_product_details_in_title'>
                    {t('general.productDetails')} {lng?.toUpperCase()}
                  </h2>
                  <img
                    src={`https://hatscripts.github.io/circle-flags/flags/${lng?.toLowerCase()}.svg`}
                    alt={lng}
                  />
                </div>
                <p className='category_product_details_in_name'>{name}</p>
                <form className='form'>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor=''>{t('dashboard.menu.category')}</label>
                      <input
                        name='product_name'
                        type='text'
                        value={selectedCategory}
                        placeholder={t('dashboard.menu.category')}
                        onChange={(e) => onChangeField(e, setName)}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor='product_name'>
                        {t('dashboard.menu.name')}
                      </label>
                      <input
                        name='product_name'
                        type='text'
                        value={name}
                        placeholder={t('dashboard.menu.name')}
                        onChange={(e) => onChangeField(e, setName)}
                      />
                    </div>
                  </div>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor='product_description'>
                        {t('dashboard.menu.description')}
                      </label>
                      <textarea
                        name='product_description'
                        value={
                          description === 'n/a' || description === null
                            ? ''
                            : description
                        }
                        maxLength={150}
                        placeholder={t('dashboard.menu.description')}
                        onChange={(e) => onChangeField(e, setDescription)}
                      />
                    </div>
                  </div>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor='product_price'>
                        {t('dashboard.menu.price')} (
                        {user.restaurant.restaurantInfo.currency?.toUpperCase()}
                        )
                      </label>
                      <input
                        name='product_price'
                        type='text'
                        value={price}
                        placeholder={t('dashboard.menu.price')}
                        onChange={(e) => onChangeField(e, setPrice)}
                        disabled={false}
                      />
                    </div>
                  </div>
                  {labels && !!labels?.length && (
                    <div className='form_columns'>
                      <div className='form_column'>
                        <label htmlFor='product_price'>
                          {t('general.addLabel')}
                        </label>
                        <DropDown
                          placeholder={t('general.addLabel')}
                          showClose={false}
                          options={labels
                            .filter(
                              (obj1) =>
                                !selectedLabels.some(
                                  (obj2) => obj2.id === obj1.id
                                )
                            )
                            .map((label, index) => ({
                              option: label,
                              el: <li key={index}>{label.label}</li>,
                            }))}
                          value={selectedLabel}
                          onChange={(e) => {
                            setSelectedLabel(null);
                            setSelectedLabels(
                              removeDuplicates([...selectedLabels, e], 'id')
                            );
                          }}
                          position='top'
                          loading={false}
                        />
                        {!!selectedLabels.length && (
                          <ul
                            className='shop_category_product_labels'
                            style={{ marginBottom: '15px' }}
                          >
                            {selectedLabels.map((label, index) => (
                              <li
                                key={index}
                                className='shop_category_product_labels_item'
                                style={{ fontSize: '14px' }}
                              >
                                {label.label}
                                <span onClick={() => onRemoveLabel(label)}>
                                  <CiSquareRemove />
                                </span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  )}
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor=''>
                        {t('dashboard.menu.productImage')} (200x200px)
                      </label>
                      {src && (
                        <>
                          <div className='form_column_img'>
                            <img
                              alt='not found'
                              src={URL.createObjectURL(src)}
                            />
                          </div>
                          <label
                            className='form_file_btn'
                            onClick={() => {
                              setSrc(null);
                            }}
                          >
                            {t('general.remove')}
                          </label>
                        </>
                      )}
                      {!src && (
                        <>
                          <div className='form_column_img'>
                            <img
                              src={`${selectedProduct.src}?t=${Date.now()}`}
                              alt={`${name}`}
                            />
                          </div>
                          <label className='form_file_btn' htmlFor='file'>
                            {t('general.edit')}
                          </label>
                          <input
                            id='file'
                            type='file'
                            name='partnerSrc'
                            onChange={(event) => {
                              setSrc(event.target.files[0]);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className='form_columns'>
                    <div className='form_column'>
                      <div className='form_column_radio'>
                        <label htmlFor=''>
                          {t('dashboard.menu.productAvailable')}
                        </label>
                        <div className='switcher'>
                          <input
                            type='checkbox'
                            onChange={() =>
                              onChangeProductActivity(
                                selectedProduct.id,
                                selectedProduct.deletedAt ? 'restore' : 'delete'
                              )
                            }
                            checked={isProductActive}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div
                  className='category_product_details_in_wrapper'
                  style={{ marginBottom: '20px' }}
                >
                  <h2 className='category_product_details_in_title'>
                    {t('general.extras')} {lng?.toUpperCase()}
                  </h2>
                  <img
                    src={`https://hatscripts.github.io/circle-flags/flags/${lng?.toLowerCase()}.svg`}
                    alt={lng}
                  />
                  {!hasDefaultIntegration && (
                    <button
                      className='category_product_details_in_btn'
                      onClick={() => setAddProductExtra(true)}
                    >
                      {t('general.addExtra')}
                    </button>
                  )}
                </div>
                {!!selectedProduct.options.length && (
                  <Table
                    route={false}
                    titles={extrasTitles}
                    rows={
                      selectedProduct.options.map((extra) => ({
                        id: extra.id,
                        name: (
                          <div
                            className='category_product_details_in_table'
                            onClick={() => {
                              setEditProductExtra(true);
                              setSelectedExtra(extra);
                            }}
                          >
                            <div className='category_product_details_in_table_label'>
                              <p>{extra.name}</p>
                              <FiEdit />
                            </div>
                          </div>
                        ),
                        price: (
                          <p>
                            {extra.price > 0
                              ? toPriceWithCurrency(
                                  Number(extra.price),
                                  user.restaurant.restaurantInfo.currency
                                )
                              : '-'}
                          </p>
                        ),
                        min: extra.restrictions ? (
                          <p>{JSON.parse(extra.restrictions).minQuantity}</p>
                        ) : (
                          '-'
                        ),
                        max: extra.restrictions ? (
                          <p>{JSON.parse(extra.restrictions).maxQuantity}</p>
                        ) : (
                          '-'
                        ),
                        actions: (
                          <div className='form_column_radio'>
                            <div
                              className='switcher'
                              style={{ paddingRight: 10 }}
                            >
                              <input
                                type='checkbox'
                                onChange={() => {
                                  onChangeExtraActivity(
                                    extra,
                                    extra.deletedAt ? 'restore' : 'delete'
                                  );
                                  setSelectedExtra(extra);
                                }}
                                checked={extra.deletedAt ? false : true}
                              />
                            </div>
                          </div>
                        ),
                      })) || []
                    }
                    hiddenFields={['id']}
                    loading={false}
                  />
                )}
                {!selectedProduct.options.length && (
                  <NoData title={t('noData.extras')} />
                )}
              </div>
              <div className='category_product_details_footer'>
                <button
                  className='category_product_details_footer_btn category_product_details_footer_btn--cancel'
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowProductDetails(false);
                    onRefresh();
                  }}
                >
                  {t('general.cancel')}
                </button>
                <button
                  className='category_product_details_footer_btn category_product_details_footer_btn--submit'
                  onClick={onSubmit}
                >
                  {t('general.save')}
                </button>
              </div>
            </>
          )}
          {loading && <Loading />}
        </div>,
        document.getElementById('modal')
      )}
      <Overlay
        onClick={(e) => {
          e.stopPropagation();
          setShowProductDetails(false);
        }}
      />
      {editProductExtra && (
        <EditProductExtra
          setEditProductExtra={setEditProductExtra}
          selectedExtra={selectedExtra}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          setSelectedExtra={setSelectedExtra}
          lng={lng}
          lngId={lngId}
          onRefresh={onRefresh}
        />
      )}
      {addProductExtra && (
        <AddProductExtra
          setAddProductExtra={setAddProductExtra}
          setSelectedProduct={setSelectedProduct}
          selectedProduct={selectedProduct}
          setSelectedExtra={setSelectedExtra}
          lng={lng}
          lngId={lngId}
          onRefresh={onRefresh}
        />
      )}
    </>
  );
}

export default memo(CategoryProductDetails);
