import React, { memo, useCallback, useEffect, useState } from 'react';
import './index.scss';
import Wrapper from '../../../components/layout/wrapper';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import { PiMoneyThin, PiWarningOctagonDuotone } from 'react-icons/pi';
import Loading from '../../../components/loading';
import { errToString } from '../../../utils';
import api from '../../../utils/api';
import NoData from '../../../components/no-data';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { FaApplePay, FaGooglePay } from 'react-icons/fa';
import Stripe from '../../../assets/icons/stripe.svg';

function PaymentMethods() {
  const { t } = useTranslation();
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [addedPaymentMethods, setAddedPaymentMethods] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onActivatePaymentMethod = useCallback(
    (el) => {
      const data = {
        credentials: el.credentials,
        isActive: true,
        tag: el.tag,
      };

      api
        .put(`/restaurant/payment-methods/${el.id}`, data)
        .then(() => {
          const audio = new Audio(sound);
          const info = `Payment method was successfully updated`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          return setAddedPaymentMethods(
            addedPaymentMethods.map((paymentMethod) => {
              if (paymentMethod.value === el.value) {
                return {
                  ...paymentMethod,
                  isActive: true,
                };
              }
              return paymentMethod;
            })
          );
        })
        .catch((err) => {
          console.log(errToString(err));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
          return setAddedPaymentMethods(
            addedPaymentMethods.map((paymentMethod) => {
              if (paymentMethod.value === el.value) {
                return {
                  ...paymentMethod,
                  isActive: false,
                };
              }
              return paymentMethod;
            })
          );
        });
    },
    [addedPaymentMethods]
  );
  const onAddPaymentMethod = useCallback(
    (el) => {
      const data = {
        tag: el.tag,
      };
      api
        .post(`/restaurant/payment-methods/${el.id}`, data)
        .then(() => {
          const audio = new Audio(sound);
          const info = `Payment method was successfully added`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          return setAddedPaymentMethods([
            ...addedPaymentMethods,
            {
              ...el,
              credentials: {
                username: '',
                password: '',
                merchantId: '',
              },
            },
          ]);
        })
        .catch((err) => {
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [addedPaymentMethods]
  );
  const onDeletePaymentMethod = useCallback(
    (el) => {
      api
        .delete(`/restaurant/payment-methods/${el.id}`)
        .then(() => {
          const audio = new Audio(sound);
          const info = `Payment method was successfully deleted`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          return setAddedPaymentMethods(
            addedPaymentMethods.filter(
              (paymentMethod) => paymentMethod.id !== el.id
            )
          );
        })
        .catch((err) => {
          console.log(errToString(err));
        });
    },
    [addedPaymentMethods]
  );
  const onSubmitSelectedPaymentMethod = useCallback(
    (e) => {
      e.preventDefault();

      setError(null);
      setPending(true);

      const data = {
        credentials: {
          username: selectedPaymentMethod.credentials.username,
          password: selectedPaymentMethod.credentials.password,
          merchantId: selectedPaymentMethod.credentials.merchantId
            ? selectedPaymentMethod.credentials.merchantId
            : '',
        },
        isActive: selectedPaymentMethod.isActive,
        tag: selectedPaymentMethod.tag,
      };

      api
        .put(`/restaurant/payment-methods/${selectedPaymentMethod.id}`, data)
        .then((res) => {
          setPending(false);
          setSelectedPaymentMethod(null);
          const audio = new Audio(sound);
          const info = `Payment method was successfully updated`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });

          audio.play();
          setAddedPaymentMethods(
            addedPaymentMethods.map((paymentMethod) => {
              if (paymentMethod.value === selectedPaymentMethod.value) {
                return res.data;
              } else {
                return paymentMethod;
              }
            })
          );
        })
        .catch((err) => {
          setPending(false);
          console.log(errToString(err));
        });
    },
    [selectedPaymentMethod, addedPaymentMethods]
  );

  useEffect(() => {
    setLoading(true);

    api
      .get('/restaurant/payment-methods')
      .then(({ data }) => {
        setAddedPaymentMethods(data);
        api
          .get('/payment-methods')
          .then(({ data }) => {
            setLoading(false);
            setPaymentMethods(data);
          })
          .catch((err) => {
            setLoading(false);
            console.log(errToString(err));
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(errToString(err));
      });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          {!loading && (
            <div className='page_in_header'>
              <h2>{t('general.activePaymentMethods')}</h2>
            </div>
          )}
          {!loading && (
            <div className='page_in_content'>
              {!!addedPaymentMethods?.length && (
                <ul className='integrations_list'>
                  {addedPaymentMethods.map((payment_method, index) => (
                    <li className='integrations_list_card' key={index}>
                      <div className='integrations_list_card_img'>
                        {payment_method.value !== 'cash' && (
                          <img
                            src={payment_method.src}
                            alt={payment_method.name}
                          />
                        )}
                        {payment_method.value === 'cash' && <PiMoneyThin />}
                      </div>
                      <div className='integrations_list_card_wrapper'>
                        <div className='integrations_list_card_wrapper_in'>
                          {windowWidth >= 500 && (
                            <p style={{ marginRight: 'auto' }}>
                              {payment_method.name}
                            </p>
                          )}
                          <button
                            style={
                              windowWidth <= 500 ? { marginLeft: 'auto' } : {}
                            }
                            onClick={() =>
                              onDeletePaymentMethod(payment_method)
                            }
                          >
                            {t('general.delete')}
                          </button>
                          {payment_method.value !== 'cash' && (
                            <button
                              style={{ marginLeft: '10px' }}
                              onClick={() =>
                                setSelectedPaymentMethod(payment_method)
                              }
                            >
                              {t('general.edit')}
                            </button>
                          )}
                          <div className='payment_methods_list_item_in_switcher'>
                            <input
                              type='checkbox'
                              checked={payment_method.isActive}
                              onChange={() =>
                                onActivatePaymentMethod(payment_method)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!addedPaymentMethods?.length && (
                <NoData title={t('noData.payments')} />
              )}
            </div>
          )}
          <div className='page_in_header'>
            <h2>{t('routes.paymentMethods')}</h2>
          </div>
          {!loading && (
            <div className='page_in_content'>
              {!!paymentMethods?.length && (
                <ul className='integrations_list'>
                  {paymentMethods
                    .filter(({ name }) => {
                      if (!addedPaymentMethods?.length) {
                        return name;
                      }
                      const excludeNames = new Set(
                        addedPaymentMethods.map(({ name }) => name)
                      );
                      return !excludeNames.has(name);
                    })
                    .map((payment_method, index) => (
                      <li className='integrations_list_card' key={index}>
                        <div className='integrations_list_card_img'>
                          <img
                            src={payment_method.src}
                            alt={payment_method.name}
                          />
                        </div>
                        <div className='integrations_list_card_wrapper'>
                          <div className='integrations_list_card_wrapper_in'>
                            <p>{payment_method.name}</p>
                            <button
                              onClick={() => onAddPaymentMethod(payment_method)}
                            >
                              {t('general.integrate')}
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  <li className='integrations_list_card'>
                    <div className='integrations_list_card_img'>
                      <FaApplePay />
                    </div>
                    <div className='integrations_list_card_wrapper'>
                      <div className='integrations_list_card_wrapper_in'>
                        <p>{t('general.comingsoon')}</p>
                      </div>
                    </div>
                  </li>
                  <li className='integrations_list_card'>
                    <div className='integrations_list_card_img'>
                      <FaGooglePay />
                    </div>
                    <div className='integrations_list_card_wrapper'>
                      <div className='integrations_list_card_wrapper_in'>
                        <p>{t('general.comingsoon')}</p>
                      </div>
                    </div>
                  </li>
                  <li className='integrations_list_card'>
                    <div className='integrations_list_card_img'>
                      <img src={Stripe} alt={'stripe'} />
                    </div>
                    <div className='integrations_list_card_wrapper'>
                      <div className='integrations_list_card_wrapper_in'>
                        <p>{t('general.comingsoon')}</p>
                      </div>
                    </div>
                  </li>
                </ul>
              )}
              {!paymentMethods?.length && (
                <NoData title={t('noData.payments')} />
              )}
            </div>
          )}
          {loading && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
      </div>
      {selectedPaymentMethod &&
        createPortal(
          <div className='payment_methods_modal'>
            <div className='payment_methods_modal_in payment_methods_modal_in--active'>
              <div className='payment_methods_modal_in_header'>
                <img
                  className='payment_methods_modal_in_header_img'
                  src={selectedPaymentMethod.src}
                  alt={selectedPaymentMethod.label}
                />
                <h2 style={{ textTransform: 'capitalize' }}>
                  {selectedPaymentMethod.value}
                </h2>
                <i onClick={() => setSelectedPaymentMethod(null)}>
                  <IoMdClose />
                </i>
              </div>
              <form className='form' onSubmit={onSubmitSelectedPaymentMethod}>
                <div className='form_columns'>
                  <div className='form_column'>
                    <label htmlFor=''>Username</label>
                    <input
                      type='text'
                      value={selectedPaymentMethod.credentials.username}
                      onChange={(e) =>
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          credentials: {
                            ...selectedPaymentMethod.credentials,
                            username: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                <div className='form_columns'>
                  <div className='form_column'>
                    <label htmlFor=''>Password</label>
                    <input
                      type='text'
                      value={selectedPaymentMethod.credentials.password}
                      onChange={(e) =>
                        setSelectedPaymentMethod({
                          ...selectedPaymentMethod,
                          credentials: {
                            ...selectedPaymentMethod.credentials,
                            password: e.target.value,
                          },
                        })
                      }
                    />
                  </div>
                </div>
                {selectedPaymentMethod.tag === 'pos' && (
                  <div className='form_columns'>
                    <div className='form_column'>
                      <label htmlFor=''>Merchant Id</label>
                      <input
                        type='text'
                        value={selectedPaymentMethod.credentials.merchantId}
                        onChange={(e) =>
                          setSelectedPaymentMethod({
                            ...selectedPaymentMethod,
                            credentials: {
                              ...selectedPaymentMethod.credentials,
                              merchantId: e.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                )}
                <div className='form_error'>
                  {error && (
                    <div className='form_error_name'>
                      <PiWarningOctagonDuotone />
                      <p>{error}</p>
                    </div>
                  )}
                </div>
                <div className='form_actions'>
                  {!pending && (
                    <button className='form_actions_btn' type={'submit'}>
                      {t('general.save')}
                    </button>
                  )}
                  {pending && (
                    <button className='form_actions_btn form_actions_btn--loading'>
                      <Loading />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>,
          document.getElementById('modal')
        )}
    </Wrapper>
  );
}

export default memo(PaymentMethods);
