import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use(function (config) {
  const splittedPathname = window.location.pathname.split('/');
  const tableId =
    splittedPathname[3] === 'tables' && splittedPathname[4]
      ? splittedPathname[4]
      : null;

  if (tableId) {
    config.headers['x-table-id'] = tableId;
  }

  // const token = localStorage.getItem("guestToken");
  // const guestAuthToken = localStorage.getItem("guestAuthToken");

  // if (token) {
  //   config.headers.Authorization = `Bearer ${token}`;
  // }
  // if (guestAuthToken) {
  //   config.headers['x-authorized-guest-token'] = guestAuthToken;
  // }

  return config;
});

export default api;
