import './index.scss';
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../../utils/api';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function EditCategory({
  setCategories,
  categories,
  setCategoryIsEditing,
  selectedCategory,
  selectedLng,
  selectedLngId,
}) {
  const { t } = useTranslation();
  const [newName, setNewName] = useState(selectedCategory.name);
  const [error, setError] = useState(null);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      const formData = new FormData();
      formData.append('name', newName);
      formData.append('languageId', selectedLngId);

      if (!newName) {
        return setError('Name cannot be empty!');
      }

      api
        .put(`/product-categories/${selectedCategory.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => {
          setCategoryIsEditing(false);
          setError(null);

          return setCategories(
            categories.map((_category) => {
              if (selectedCategory.id === _category.id) {
                return res.data;
              }
              return _category;
            })
          );
        })
        .catch((err) => setError(t(`errors.${errToString(err)}`)));
    },
    [
      t,
      newName,
      setCategories,
      categories,
      selectedCategory,
      setCategoryIsEditing,
      selectedLngId,
    ]
  );

  return createPortal(
    <div className='edit_category' onClick={(e) => e.stopPropagation()}>
      <div className='edit_category_in edit_category_in--active'>
        <div className='edit_category_in_header'>
          <div className='edit_category_in_header_title'>
            <h2>
              {t('general.editCategory')} {selectedLng?.toUpperCase()}
            </h2>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${selectedLng?.toLowerCase()}.svg`}
              width='48'
              alt={selectedLng}
            />
          </div>
          <i onClick={() => setCategoryIsEditing(false)}>
            <IoMdClose />
          </i>
        </div>
        <form className='form' onSubmit={onSubmit}>
          <div className='form_columns'>
            <div className='form_column'>
              <input
                type='text'
                value={newName}
                placeholder={t('general.categoryName')}
                onChange={(e) => onChangeField(e, setNewName)}
              />
            </div>
          </div>
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
              {t('general.save')}
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

EditCategory.propTypes = {
  selectedCategory: PropTypes.object.isRequired,
  setCategoryIsEditing: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
  selectedLng: PropTypes.string.isRequired,
  selectedLngId: PropTypes.number.isRequired,
};

export default memo(EditCategory);
