import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toPriceWithCurrency } from '../../../../../utils';
import { IoIosArrowBack, IoIosCloseCircle, IoIosRemove } from 'react-icons/io';
import { GrFormAdd } from 'react-icons/gr';
import NoData from '../../../../../components/no-data';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../../components/loading';
import { BsThreeDots } from 'react-icons/bs';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { FaPeopleArrows } from 'react-icons/fa';
import { FaNoteSticky } from 'react-icons/fa6';
import AuthContext from '../../../../../context/auth/auth-context';

function TerminalBasket({
  onRemoveItem,
  onAddItem,
  basketProducts,
  onCreateOrder,
  pending,
  onClear,
  setSelectedTable,
  showBasket,
  setShowBasket,
  windowWidth,
}) {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const morePopupRef = useRef(null);
  const [showMore, setShowMore] = useState(false);

  const totalAmount = useMemo(() => {
    const pricesArray = basketProducts.map((product) => {
      return (
        (product.options.reduce((acc, option) => acc + option.price, 0) +
          product.price) *
        product.qty
      );
    });
    return pricesArray.reduce((acc, price) => acc + price, 0);
  }, [basketProducts]);

  const onAddComment = useCallback(() => {
    setShowMore(false);
    alert('Coming soon!');
  }, []);

  return (
    <>
      <div
        className={cn('terminal_basket', {
          'terminal_basket--show': showBasket,
        })}
      >
        <div className='terminal_basket_title'>
          {windowWidth < 1190 && (
            <span className='terminal_basket_title_icon'>
              <IoIosArrowBack onClick={() => setShowBasket(false)} />
            </span>
          )}
          <p>{t('dashboard.terminal.basket')}</p>
          <div
            ref={morePopupRef}
            className='terminal_basket_icon'
            onClick={() => setShowMore(true)}
          >
            <BsThreeDots />
          </div>
          {showMore &&
            morePopupRef &&
            createPortal(
              <>
                <div
                  className={cn('terminal_basket_popup', {
                    'terminal_basket_popup--show': showMore,
                  })}
                  style={{
                    top: morePopupRef.current.getBoundingClientRect().top + 45,
                    right: 20,
                  }}
                >
                  <button
                    className='page_in_content_actions_more_btn'
                    onClick={onAddComment}
                  >
                    <FaNoteSticky />
                    {t('general.comment')}
                  </button>
                  <button
                    className='page_in_content_actions_more_btn'
                    onClick={() => {
                      setSelectedTable(null);
                      setShowMore(false);
                    }}
                  >
                    <FaPeopleArrows />
                    {t('general.transfer')}
                  </button>
                  <button
                    className='page_in_content_actions_more_btn'
                    onClick={() => {
                      onClear();
                      setShowMore(false);
                    }}
                  >
                    <IoIosCloseCircle />
                    {t('general.reset')}
                  </button>
                </div>
                <div
                  className='terminal_basket_popup_overlay'
                  onClick={() => setShowMore(false)}
                />
              </>,
              document.getElementById('modal')
            )}
        </div>
        <ul className='terminal_basket_list'>
          {!!basketProducts.length &&
            basketProducts.map((product, index) => (
              <li key={index} className='terminal_basket_list_item'>
                <div className='terminal_basket_list_item_wrapper'>
                  <div className='terminal_basket_list_item_header'>
                    {basketProducts.find(({ id }) => id === product.id) && (
                      <button
                        className='terminal_basket_actions_btn terminal_basket_actions_btn--remove'
                        onClick={() => {
                          return onRemoveItem({
                            id: product.id,
                            options: product.options,
                          });
                        }}
                      >
                        <IoIosRemove />
                      </button>
                    )}
                    <p className='terminal_basket_list_item_header_name'>
                      {product.name}
                    </p>
                    <span>{product.qty}x</span>
                    <button
                      className='terminal_basket_actions_btn terminal_basket_actions_btn--add'
                      onClick={() => {
                        return onAddItem({
                          ...product,
                          options: product.options,
                        });
                      }}
                    >
                      <GrFormAdd />
                    </button>
                    <p className='terminal_basket_list_item_header_price'>
                      {toPriceWithCurrency(
                        Number(
                          product.price +
                            product.options.reduce(
                              (acc, val) => acc + val.price,
                              0
                            )
                        ),
                        user.restaurant.restaurantInfo.currency
                      )}
                    </p>
                  </div>
                  <div className='terminal_basket_list_item_options'>
                    {!!product.options?.length && (
                      <span>{t('dashboard.terminal.extras')}:</span>
                    )}
                    {product.options.map((option, index) => (
                      <p key={index}>{option.name}</p>
                    ))}
                  </div>
                </div>
              </li>
            ))}
          {!basketProducts.length && (
            <li className='terminal_basket_list_item'>
              <NoData title={t('noData.products')} />
            </li>
          )}
        </ul>
        <div className='terminal_basket_amount'>
          <p>{t('dashboard.terminal.total')}:</p>
          <span>
            {toPriceWithCurrency(
              Number(totalAmount),
              user.restaurant.restaurantInfo.currency
            )}
          </span>
        </div>
        <div className='terminal_basket_actions'>
          {!pending && (
            <button
              className='terminal_basket_actions_submit'
              onClick={onCreateOrder}
            >
              {t('dashboard.terminal.create')}
            </button>
          )}
          {pending && (
            <button
              className='terminal_basket_actions_submit terminal_basket_actions_submit--loading'
              onClick={onCreateOrder}
            >
              <Loading />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default memo(TerminalBasket);
