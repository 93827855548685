import './index.scss';
import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import sound from '../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import SocketContext from '../../context/socket/socket-context';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { socket } = useContext(SocketContext);
  const [invoices, setInvoices] = useState([]);
  const [updatedInvoiceId, setUpdatedInvoiceId] = useState(null);
  const [callingTableId, setCallingTableId] = useState(null);
  const { t } = useTranslation();

  const outletContext = useMemo(
    () => ({
      invoices: {
        invoices,
        setInvoices,
      },
      updatedInvoice: {
        updatedInvoiceId,
        setUpdatedInvoiceId,
      },
      callingTable: {
        callingTableId,
        setCallingTableId,
      },
    }),
    [
      invoices,
      setInvoices,
      updatedInvoiceId,
      setUpdatedInvoiceId,
      callingTableId,
      setCallingTableId,
    ]
  );

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on('invoice:add', (invoice) => {
      const audio = new Audio(sound);
      const info = `#${invoice.externalId} ${t('notifications.addInvoice')}`;
      toast.info(info, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      audio.play();

      setUpdatedInvoiceId(invoice.id);
      setInvoices([invoice, ...invoices]);
    });
    socket.on('invoice:update', (invoice) => {
      const audio = new Audio(sound);
      const info = `#${invoice.externalId} ${t('notifications.updateInvoice')}`;
      toast.info(info, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      audio.play();

      setUpdatedInvoiceId(invoice.id);
      setInvoices(
        invoices.map((_invoice) => {
          if (_invoice.id === invoice.id) {
            return invoice;
          }
          return _invoice;
        })
      );
    });
    socket.on('waiter:call', (tableId) => {
      const audio = new Audio(sound);
      const info = `#${tableId} ${t('notifications.tableCalling')}`;
      toast.info(info, {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      audio.play();

      setCallingTableId(Number(tableId));
    });

    return () => {
      socket.off('invoice:add');
      socket.off('invoice:update');
      socket.off('waiter:call');
    };
  }, [t, socket, invoices]);

  return <Outlet context={outletContext} />;
}

export default memo(Dashboard);
