import { memo, useCallback, useMemo } from 'react';
import './index.scss';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import PropTypes from 'prop-types';
import cn from 'classnames';
import DropDown from '../drop-down';
import { useTranslation } from 'react-i18next';

function Pagination({
  pagesCount,
  limit,
  setOffset,
  limitPlaceholder,
  limits,
  currentPage,
  setCurrentPage,
  onChange,
}) {
  const { t } = useTranslation();
  const pages = useMemo(() => {
    const pages = Array.from(Array(pagesCount).keys());
    const start = currentPage - 3 > 0 ? currentPage - 3 : 0;
    const end = currentPage + 4 < pagesCount ? currentPage + 4 : pagesCount;
    const pagination = [...pages.slice(start, end)];

    if (start > 1) {
      pagination.unshift('...');
    }
    if (start !== 0) {
      pagination.unshift(0);
    }
    if (end < pagesCount - 1) {
      pagination.push('...');
    }
    if (end !== pagesCount) {
      pagination.push(pagesCount - 1);
    }

    return pagination;
  }, [pagesCount, currentPage]);
  const onChangePage = useCallback(
    (page) => {
      if (page < 0) {
        page = 0;
      }
      if (page >= pagesCount) {
        page = pagesCount - 1;
      }

      setCurrentPage(page);
      setOffset(page * limit.value);
    },
    [limit, pagesCount, setOffset, setCurrentPage]
  );

  return (
    <div className='pagination'>
      <div className='pagination_limit'>
        <DropDown
          placeholder={limitPlaceholder}
          options={limits}
          value={limit}
          onChange={onChange}
          position='top'
          theme='pagination'
        />
      </div>
      <p>{t('components.pagination.records')}</p>
      <ul className='pagination_list'>
        <BsChevronLeft onClick={() => onChangePage(currentPage - 1)} />
        {pages.map((page, index) =>
          typeof page === 'number' ? (
            <li
              key={`${page}-${index}`}
              className={cn('pagination_list_page', {
                'pagination_list_page--active': currentPage === page,
              })}
              onClick={() => onChangePage(page)}
            >
              {page + 1}
            </li>
          ) : (
            <span key={index}>...</span>
          )
        )}
        <BsChevronRight onClick={() => onChangePage(currentPage + 1)} />
      </ul>
    </div>
  );
}

Pagination.propTypes = {
  pagesCount: PropTypes.number.isRequired,
  limit: PropTypes.object.isRequired,
  setOffset: PropTypes.func.isRequired,
  limitPlaceholder: PropTypes.string,
  limits: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default memo(Pagination);
