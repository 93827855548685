import './index.scss';
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

function PassCodePopup({ setShowPasscode, passcode }) {
  const { t } = useTranslation();

  return createPortal(
    <div className='passcode_popup' onClick={() => setShowPasscode(false)}>
      <div className='passcode_popup_in passcode_popup_in--active'>
        <h3>{t('general.passcode')}</h3>
        <h2>{passcode ? passcode : 'no passcode'}</h2>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

export default memo(PassCodePopup);
