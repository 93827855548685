import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './index.scss';
import Wrapper from '../../../components/layout/wrapper';
import { useTranslation } from 'react-i18next';
import api from '../../../utils/api';
import { errToString } from '../../../utils';
import { BsFillQuestionSquareFill, BsThreeDots } from 'react-icons/bs';
import Poster from '../../../assets/integrations/joinposter.png';
import Iiko from '../../../assets/integrations/iiko.png';
import Loading from '../../../components/loading';
import { createPortal } from 'react-dom';
import Table from '../../../components/table';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import Overlay from '../../../components/layout/overlay';
import cn from 'classnames';
import { MdDelete, MdUpdate } from 'react-icons/md';
import Warning from '../../../components/warning';
import NoData from '../../../components/no-data';
import sound from '../../../assets/sounds/notification.wav';
import SmartRest from '../../../assets/icons/smartrest.jpeg';
import RKeeper from '../../../assets/icons/rkeeper.png';
import { toast } from 'react-toastify';
import moment from 'moment';

function Integrations() {
  const { t } = useTranslation();
  const morePopupRef = useRef({});
  const [integrations, setIntegrations] = useState(null);
  const [selectedToAdd, setSelectedToAdd] = useState(null);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const [login, setLogin] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [keyDate, setKeyDate] = useState(new Date());
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(null);
  const [activeIntegrations, setActiveIntegrations] = useState(null);
  const [loading, setLoading] = useState(false);

  const IntegrationsTitles = useMemo(
    () => [
      {
        name: t('components.tables.name'),
        size: 150,
      },
      {
        name: 'Info',
        size: 150,
      },
      {
        name: t('components.tables.accessToken'),
        size: 300,
      },
      {},
    ],
    [t]
  );

  const onDelete = useCallback(
    (e) => {
      e.preventDefault();

      setError(null);
      setLoading(true);

      api
        .delete(`/restaurant/integrations/${selectedToDelete.id}`)
        .then(() => {
          setSelectedToDelete(null);
          setError(null);
          setShowMore(null);
          setLoading(false);
          console.log('Integation is deleted!');

          api
            .delete('/restaurant/import')
            .then(() => {
              console.log('Menu deleted!');
              window.location.reload();
            })
            .catch((err) => {
              const audio = new Audio(sound);
              const info = t('errors.somethingWentWrong');
              toast.info(info, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                type: 'error',
              });
              audio.play();
              console.log(errToString(err));
            });
        })
        .catch((err) => {
          const audio = new Audio(sound);
          const info = t('errors.somethingWentWrong');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setError(errToString(err));
          setLoading(false);
          console.log(errToString(err));
        });
    },
    [t, selectedToDelete]
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setError(null);
      setLoading(true);

      const data = {
        isActive: true,
        credentials: {
          accessToken: accessToken,
        },
      };

      if (selectedToAdd.value === 'poster') {
        data.credentials.login = login;
      }

      if (selectedToAdd.value === 'iiko') {
        data.credentials.keyDate = moment(keyDate).utc().format();
      }

      if (!!activeIntegrations.length) {
        return alert('You have active integration! You cannot add new one.');
      }

      api
        .post(`/restaurant/integrations/${selectedToAdd.id}`, data)
        .then(() => {
          setSelectedToAdd(null);
          setError(null);
          console.log('Integration is added!');
          api
            .delete('/restaurant/import')
            .then(() => {
              console.log('Menu deleted!');
              window.location.reload();
            })
            .catch((err) => {
              const audio = new Audio(sound);
              const info = t('errors.somethingWentWrong');
              toast.info(info, {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
              audio.play();
              console.log(errToString(err));
              setLoading(false);
            });
        })
        .catch((err) => {
          const audio = new Audio(sound);
          const info = t('errors.somethingWentWrong');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setError(null);
          setLoading(false);
          console.log(errToString(err));
        });
    },
    [activeIntegrations, accessToken, login, selectedToAdd, t, keyDate]
  );

  useEffect(() => {
    api
      .get('/restaurant/integrations')
      .then(({ data }) => {
        setActiveIntegrations(data);

        api
          .get('/integrations')
          .then(({ data }) => {
            setIntegrations(data);
          })
          .catch((err) => {
            errToString(err);
          });
      })
      .catch((err) => {
        errToString(err);
      });
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t(`routes.integrations`)}</h2>
          </div>
          {activeIntegrations && integrations && (
            <div className='page_in_content'>
              {!!activeIntegrations.length ? (
                <Table
                  route={false}
                  titles={IntegrationsTitles}
                  hiddenFields={['id']}
                  rows={
                    activeIntegrations.map((integration) => ({
                      integration: (
                        <p style={{ textTransform: 'uppercase' }}>
                          {integration.value}
                        </p>
                      ),
                      login: <p>{integration.credentials.login}</p>,
                      accessToken: <p>{integration.credentials.accessToken}</p>,
                      actions: (
                        <div className='page_in_content_actions'>
                          <div
                            ref={(el) =>
                              (morePopupRef.current[integration.id] = el)
                            }
                            className='page_in_content_icon'
                            onClick={() => {
                              if (showMore === integration.id) {
                                return setShowMore(null);
                              }
                              return setShowMore(integration.id);
                            }}
                          >
                            <BsThreeDots />
                          </div>
                          {showMore === integration.id &&
                            morePopupRef &&
                            createPortal(
                              <>
                                <div
                                  className={cn(
                                    'page_in_content_actions_popup',
                                    {
                                      'page_in_content_actions_popup--show':
                                        showMore,
                                    }
                                  )}
                                  style={{
                                    top:
                                      morePopupRef.current[
                                        integration.id
                                      ].getBoundingClientRect().top + 40,
                                    right: 30,
                                  }}
                                >
                                  <button
                                    className='page_in_content_actions_more_btn'
                                    onClick={() =>
                                      setSelectedToDelete(integration)
                                    }
                                  >
                                    <MdDelete />
                                    {t('general.delete')}
                                  </button>
                                  {integration.value === 'iiko' && (
                                    <button
                                      className='page_in_content_actions_more_btn'
                                      onClick={() =>
                                        setSelectedToDelete(integration)
                                      }
                                    >
                                      <MdUpdate />
                                      {t('general.update')}
                                    </button>
                                  )}
                                </div>
                                <Overlay
                                  isTransparent={true}
                                  onClick={() => setShowMore(null)}
                                />
                              </>,
                              document.getElementById('modal')
                            )}
                        </div>
                      ),
                    })) || []
                  }
                  loading={false}
                />
              ) : (
                <NoData title={t('noData.integrations')} />
              )}
              <ul className='integrations_list'>
                {integrations
                  .filter(
                    (obj) =>
                      !activeIntegrations.map((obj) => obj.id).includes(obj.id)
                  )
                  .map((integration) => (
                    <li className='integrations_list_card' key={integration.id}>
                      {integration.value === 'poster' && (
                        <div className='integrations_list_card_img'>
                          <img src={Poster} alt={integration.value} />
                        </div>
                      )}
                      {integration.value === 'iiko' && (
                        <div className='integrations_list_card_img'>
                          <img src={Iiko} alt={integration.value} />
                        </div>
                      )}
                      <div className='integrations_list_card_wrapper'>
                        <div className='integrations_list_card_wrapper_in'>
                          <p>{integration.value}</p>
                          <button onClick={() => setSelectedToAdd(integration)}>
                            {t('general.integrate')}
                          </button>
                        </div>
                        <a href='!#' target='_blank'>
                          {integration.value}
                        </a>
                      </div>
                    </li>
                  ))}
                <li className='integrations_list_card'>
                  <div className='integrations_list_card_img'>
                    <img src={SmartRest} alt={'smart rest'} />
                  </div>
                  <div className='integrations_list_card_wrapper'>
                    <div className='integrations_list_card_wrapper_in'>
                      <p>Smart Rest {t('general.comingsoon')}</p>
                    </div>
                    <a href='!#' target='_blank'>
                      Smart Rest
                    </a>
                  </div>
                </li>
                <li className='integrations_list_card'>
                  <div className='integrations_list_card_img'>
                    <img src={RKeeper} alt={'R Keeper'} />
                  </div>
                  <div className='integrations_list_card_wrapper'>
                    <div className='integrations_list_card_wrapper_in'>
                      <p>R Keeper {t('general.comingsoon')}</p>
                    </div>
                    <a href='!#' target='_blank'>
                      R Keeper
                    </a>
                  </div>
                </li>
              </ul>
              <div className='integrations_contacts'>
                <div className='integrations_contacts_in'>
                  <h3>
                    <BsFillQuestionSquareFill />
                    {t('dashboard.integrations.contactsHeader')}
                  </h3>
                  <span>
                    {t('dashboard.integrations.contactsBody')}{' '}
                    <a href='mailto:support@qrrobo.com'>support@qrrobo.com</a>
                  </span>
                </div>
              </div>
            </div>
          )}
          {(!integrations || !activeIntegrations) && (
            <div className='loading_fullscreen'>
              <Loading />
            </div>
          )}
        </div>
        {selectedToAdd &&
          createPortal(
            <>
              <div className='integrations_form'>
                <div className='integrations_form_in integrations_form_in--active'>
                  <form className='form' onSubmit={onSubmit}>
                    {selectedToAdd.value === 'poster' && (
                      <>
                        <div className='form_columns'>
                          <div className='form_column'>
                            <label htmlFor='login'>
                              {t('components.tables.login')}
                            </label>
                            <input
                              name='login'
                              type='text'
                              value={login}
                              placeholder={t('components.tables.login')}
                              onChange={(e) => setLogin(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='form_columns'>
                          <div className='form_column'>
                            <label htmlFor='accessToken'>
                              {t('components.tables.accessToken')}
                            </label>
                            <input
                              name='accessToken'
                              type='text'
                              value={accessToken}
                              placeholder={t('components.tables.accessToken')}
                              onChange={(e) => setAccessToken(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {selectedToAdd.value === 'iiko' && (
                      <>
                        <div className='form_columns'>
                          <div className='form_column'>
                            <label htmlFor='API key'>API key</label>
                            <input
                              name='API key'
                              type='text'
                              value={accessToken}
                              placeholder='API key'
                              onChange={(e) => setAccessToken(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='form_columns'>
                          <div className='form_column'>
                            <label htmlFor='key validation date'>
                              {t('integrations.keyValidationDate')}
                            </label>
                            <input
                              name='key validation date'
                              type='date'
                              value={keyDate}
                              placeholder={t('integrations.keyValidationDate')}
                              onChange={(e) => setKeyDate(e.target.value)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className='form_error'>
                      {error && (
                        <div className='form_error_name'>
                          <PiWarningOctagonDuotone />
                          <p>{error}</p>
                        </div>
                      )}
                    </div>
                    <div className='form_actions'>
                      {loading && (
                        <button className='form_actions_btn form_actions_btn--loading'>
                          <Loading />
                        </button>
                      )}
                      {!loading && (
                        <button className='form_actions_btn' type={'submit'}>
                          {t('general.save')}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
              <Overlay
                onClick={() => {
                  setSelectedToAdd(null);
                  setLogin('');
                  setAccessToken('');
                  setKeyDate('');
                  setError(null);
                }}
              />
            </>,
            document.getElementById('modal')
          )}
        {selectedToDelete &&
          createPortal(
            <Warning
              description={t('components.warning.deleteIntegration')}
              onCancel={() => {
                setSelectedToDelete(null);
                setShowMore(false);
              }}
              onSubmit={onDelete}
            />,
            document.getElementById('modal')
          )}
      </div>
    </Wrapper>
  );
}

export default memo(Integrations);
