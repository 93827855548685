import { useReducer, useCallback, memo } from 'react';
import UserContext from './user-context';

const defaultUserState = {
  user: null,
  setUser: () => {},
};

const userReducer = (state, action) => {
  if (action.type === 'SET_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }

  return defaultUserState;
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, defaultUserState);

  const setUser = useCallback(
    (user) => {
      dispatch({
        type: 'SET_USER',
        payload: { user },
      });
    },
    [dispatch]
  );

  const userContext = {
    user: state.user,
    setUser: setUser,
  };

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};

export default memo(UserProvider);
