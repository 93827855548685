import React, { memo, useState } from 'react';
import './index.scss';
import Wrapper from '../../../components/layout/wrapper';
import { useTranslation } from 'react-i18next';
import { CiImageOn } from 'react-icons/ci';

function ShopCustomization() {
  const { t } = useTranslation();
  const [src, setSrc] = useState('');
  const [mainColorValue, setMainColorValue] = useState('FFFFFF');

  console.log(mainColorValue.length);
  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.customization')}</h2>
          </div>
          <div className='page_in_content'>
            <form className='form' style={{ paddingBottom: '10px' }}>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('general.chooseLogo')} (150x25px)</label>
                  {src && (
                    <>
                      <div className='form_column_img form_column_img--small'>
                        <img alt='logo' src={URL.createObjectURL(src)} />
                      </div>
                      <div className='form_column_img_btn_wrapper'>
                        <label
                          className='form_file_btn'
                          onClick={() => {
                            setSrc(null);
                          }}
                        >
                          {t('general.remove')}
                        </label>
                        <label
                          style={{ marginLeft: '10px' }}
                          className='form_file_btn'
                          onClick={() => {
                            setSrc(null);
                          }}
                        >
                          {t('general.save')}
                        </label>
                      </div>
                    </>
                  )}
                  {!src && (
                    <>
                      <div className='form_column_img form_column_img--small'>
                        <CiImageOn />
                      </div>
                      <label className='form_file_btn' htmlFor='file'>
                        {t('general.edit')}
                      </label>
                      <input
                        id='file'
                        type='file'
                        name='partnerSrc'
                        onChange={(event) => {
                          setSrc(event.target.files[0]);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </form>
            <form className='form' style={{ paddingTop: '0' }}>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('general.chooseMainColor')}</label>
                  <div className='form_color_picker'>
                    <div className='form_color_picker_input'>
                      <span>#</span>
                      <input
                        style={{ textTransform: 'capitalize' }}
                        value={mainColorValue}
                        type='text'
                        maxLength={6}
                        onChange={(e) => setMainColorValue(`${e.target.value}`)}
                      />
                    </div>
                    <span
                      className='form_color_picker_color'
                      style={{
                        height: '50px',
                        width: '50px',
                        background: `#${mainColorValue.length === 6 ? mainColorValue : 'FFFFFF'}`,
                      }}
                    />
                  </div>
                  {mainColorValue.length === 6 && (
                    <button>{t('general.save')}</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(ShopCustomization);
