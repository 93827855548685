import React from 'react';

const TerminalContext = React.createContext({
  products: [],
  onAddItem: () => {},
  onRemoveItem: () => {},
  onClear: () => {},
});

export default TerminalContext;
