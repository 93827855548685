import './index.scss';
import { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import TableRow from './table-row';
import TableColumn from './table-column';

function Table({
  route,
  setId,
  setRow,
  title,
  titles,
  rows,
  hiddenFields = [],
  onClick,
}) {
  const ref = useRef();
  const navigate = useNavigate();
  const [colMinWidth, setColMinWidth] = useState(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const colMinWidth =
      (ref.current?.offsetWidth -
        titles.reduce((prev, current) => prev + (current?.size || 0), 0)) /
      titles.filter((title) => !title.size).length;
    setColMinWidth(colMinWidth);

    window.onresize = () => {
      const colMinWidth =
        (ref.current?.offsetWidth -
          titles.reduce((prev, current) => prev + (current?.size || 0), 0)) /
        titles.filter((title) => !title.size).length;
      setColMinWidth(colMinWidth);
    };

    return () => {
      window.onresize = null;
    };
    // eslint-disable-next-line
  }, [ref.current, titles]);

  return (
    <>
      {title && <p className='table_title'>{title}</p>}
      <table ref={ref} className='table'>
        <thead className='table_header'>
          <tr>
            {colMinWidth &&
              titles.map(
                (
                  { name, isSortable, size, onSortByAsc, onSortByDesc },
                  index
                ) => (
                  <TableColumn
                    key={index}
                    name={name}
                    isSortable={isSortable}
                    size={size}
                    onSortByAsc={onSortByAsc}
                    onSortByDesc={onSortByDesc}
                    colMinWidth={colMinWidth}
                  />
                )
              )}
          </tr>
        </thead>
        <tbody className='table_body'>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              row={row}
              setRow={setRow}
              route={route}
              hiddenFields={hiddenFields}
              className={cn({ 'table--has_route': route || setRow || onClick })}
              onClick={() => {
                if (onClick) {
                  return onClick(row);
                }
                if (setRow) {
                  return setRow(row.row);
                }
                if (setId) {
                  return setRow(row.id);
                }
                if (route) {
                  return navigate(`/${route}/${row.id}`);
                }
              }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
}

Table.propTypes = {
  route: PropTypes.bool,
  titles: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  hiddenFields: PropTypes.array,
  setRow: PropTypes.func,
};

export default memo(Table);
