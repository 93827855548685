import { memo, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import ShopSignUp from '../../shop-sign-up';
import ShopAccount from '../../shop-account';
import { useLocation } from 'react-router-dom';
import UserContext from '../../../../../context/user/user-context';
import api from '../../../../../utils/shop-api';
import { errToString } from '../../../../../utils';

function ShopHeaderProfile({ show, setShow }) {
  const { pathname } = useLocation();
  const { user } = useContext(UserContext);
  const [invoice, setInvoice] = useState(null);
  const branchId = pathname.split('/')[2];
  const [lngId, setLngId] = useState(null);

  useEffect(() => {
    api
      .get(`/shop/${branchId}/languages`)
      .then((res) => {
        setLngId(
          res.data.filter(({ isPrimary }) => isPrimary).map(({ id }) => id)[0]
        );
      })
      .catch((err) => console.log(errToString(err)));

    if (lngId) {
      api
        .get(`/shop/${branchId}/invoice?languageId=${lngId}`)
        .then(({ data }) => setInvoice(data))
        .catch((err) => console.log(errToString(err)));
    }
  }, [branchId, lngId]);

  useEffect(() => {
    if (user) {
      if (!user.customer) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [setShow, user]);

  return (
    <>
      {show &&
        invoice &&
        createPortal(
          <>
            {!user.customer &&
              pathname.split('/')[5] !== 'success' &&
              pathname.split('/')[5] !== 'error' && (
                <ShopSignUp
                  setShow={setShow}
                  isDarkMode={false}
                  invoiceId={invoice.id}
                />
              )}
            {user.customer && (
              <ShopAccount
                setShow={setShow}
                isDarkMode={false}
                lngId={lngId}
                branchId={branchId}
              />
            )}
          </>,
          document.getElementById('modal')
        )}
    </>
  );
}

export default memo(ShopHeaderProfile);
