import './index.scss';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import Wrapper from '../../../../components/layout/wrapper';
import Table from '../../../../components/table';
import NoData from '../../../../components/no-data';
import api from '../../../../utils/api';
import Loading from '../../../../components/loading';
import { errToString, toPriceWithCurrency } from '../../../../utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import AuthContext from '../../../../context/auth/auth-context';

function Customer() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const customerId = pathname.split('/')[2];

  const CustomersTitles = useMemo(
    () => [
      {
        name: t('routes.orders'),
      },
    ],
    [t]
  );

  useEffect(() => {
    api
      .get(`/customers/${customerId}`)
      .then((res) => {
        setCustomer(res.data.customer);
        setLoading(false);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        setLoading(false);
      });
  }, [t, customerId]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            {!customer && (
              <p>
                <span onClick={() => navigate('/customers')}>
                  {t('routes.customers')}
                </span>
                / {t('general.customer')} ...
              </p>
            )}
            {customer && (
              <p>
                <span onClick={() => navigate('/customers')}>
                  {t('routes.customers')}
                </span>
                / {customer.customer?.email}
              </p>
            )}
          </div>
          <div className='page_in_content'>
            {!loading && !!customer.invoices.length && (
              <Table
                loading={false}
                hiddenFields={['id']}
                titles={CustomersTitles}
                rows={
                  customer.invoices?.map((invoice, index) => ({
                    id: invoice.id,
                    order: (
                      <div key={index} className='page_in_content_table'>
                        {invoice.orders.map((order, index) => (
                          <div key={index} className='page_in_content_table'>
                            <p>
                              {t('general.order')} #{order.id}
                            </p>
                            <p className='page_in_content_table_invoice'>
                              {t('components.tables.branch')}:{' '}
                              {invoice.branch.name}
                            </p>
                            <p className='page_in_content_table_price'>
                              {t('general.cash')}
                            </p>
                            <p className='page_in_content_table_price'>
                              {toPriceWithCurrency(
                                Number(
                                  invoice.orders.reduce(
                                    (acc, order) =>
                                      acc + order.price * order.quantity,
                                    0
                                  )
                                ),
                                user.restaurant.restaurantInfo.currency
                              )}
                            </p>
                            <p
                              className='page_in_content_table_date page_in_content_table_date--left'
                              style={{ paddingRight: '15px' }}
                            >
                              {moment(order.createdAt).format(
                                `DD MMM YYYY, HH:mm`
                              )}
                            </p>
                          </div>
                        ))}
                      </div>
                    ),
                  })) || []
                }
              />
            )}
            {!loading && !customer.invoices.length && (
              <NoData title={t('noData.orders')} />
            )}
            {loading && <Loading />}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Customer);
