import './index.scss';
import React, { memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import { IoIosCloseCircle, IoMdClose } from 'react-icons/io';
import { BiSolidErrorCircle } from 'react-icons/bi';
import { AiOutlineFileExcel } from 'react-icons/ai';
import api from '../../../../utils/api';
import sound from '../../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import Loading from '../../../../components/loading';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function MenuImportPopup({
  setImportMenuFormIsOpen,
  setImporting,
  onRefresh,
  branch,
}) {
  const { t } = useTranslation();
  const [template, setTemplate] = useState(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);

  const onDownloadTemplate = useCallback(
    (e) => {
      e.preventDefault();
      api
        .get(`/restaurant/export/default`, {
          responseType: 'blob',
        })
        .then((res) => {
          const filename = res.headers['content-disposition']
            .split('filename=')[1]
            .replace('.xlsx', '')
            .replaceAll('"', '');
          const url = window.URL.createObjectURL(res.data);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        })
        .catch((err) => {
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [t]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);
      setImporting(true);
      setPending(true);

      const formData = new FormData();

      if (template) {
        formData.append('products', template);
      }

      api
        .post(`/restaurant/import/default?branchId=${branch.id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(() => {
          const audio = new Audio(sound);
          const info = t('notifications.importMenu');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setImportMenuFormIsOpen(false);
          setImporting(false);
          setPending(false);
          onRefresh();
        })
        .catch((err) => {
          setError(t(`errors.${errToString(err)}`));
          setPending(false);
          setImporting(false);
          const audio = new Audio(sound);
          const info = t(
            `Importing failed! Please check the excel you have imported`
          );
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
        });
    },
    [onRefresh, setImporting, template, t, setImportMenuFormIsOpen, branch]
  );

  return createPortal(
    <div className='menu_import'>
      <div className='menu_import_in menu_import_in--active'>
        <div className='menu_import_in_header'>
          <div className='edit_category_in_header_title'>
            <h2>{t('general.import')}</h2>
          </div>
          <i onClick={() => setImportMenuFormIsOpen(false)}>
            <IoMdClose />
          </i>
        </div>
        <form className='form' onSubmit={onSubmit}>
          <div className='menu_import_in_template'>
            <BiSolidErrorCircle />
            <h2 className='menu_import_in_template_descrip'>
              {t('dashboard.menu.templateDescription')}
              <a href='!#' onClick={onDownloadTemplate}>
                {t('dashboard.menu.template')} <AiOutlineFileExcel />
              </a>
            </h2>
          </div>
          <div className='form_columns'>
            <div className='form_column'>
              {template && (
                <div className='menu_import_in_template_remove'>
                  <div className='form_file_btn form_file_btn--uploaded'>
                    {template.name}
                  </div>
                  <button
                    className='menu_import_in_template_remove_btn'
                    onClick={() => setTemplate(null)}
                  >
                    <IoIosCloseCircle />
                  </button>
                </div>
              )}
              {!template && (
                <>
                  <label className='form_file_btn' htmlFor='file'>
                    {t('general.uploadTemplate')} <AiOutlineFileExcel />
                  </label>
                  <input
                    id='file'
                    type='file'
                    name='menu template'
                    onChange={(event) => {
                      setTemplate(event.target.files[0]);
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            {!pending && (
              <button className='form_actions_btn' type={'submit'}>
                {t('general.save')}
              </button>
            )}
            {pending && (
              <button
                className='form_actions_btn form_actions_btn--loading'
                type={'submit'}
              >
                <Loading />
              </button>
            )}
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

export default memo(MenuImportPopup);
