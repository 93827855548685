import './index.scss';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Wrapper from '../../../components/layout/wrapper';
import Table from '../../../components/table';
import NoData from '../../../components/no-data';
import { AiTwotoneEdit } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import EditStaff from './edit-staff';
import Warning from '../../../components/warning';
import api from '../../../utils/api';
import Loading from '../../../components/loading';
import AuthContext from '../../../context/auth/auth-context';
import { LIMITS } from '../../../constants/general';
import Pagination from '../../../components/pagination';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function Staff() {
  const authCtx = useContext(AuthContext);
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [externalId, setExternalId] = useState('');
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [addNewStaff, setAddNewStaff] = useState(false);
  const [staffIsEditing, setStaffIsEditing] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [staff, setStaff] = useState(null);
  const [integrations, setIntegrations] = useState(null);

  const StaffTitles = useMemo(
    () => [
      {
        name: t('components.tables.name'),
        size: 150,
      },
      {
        name: t('components.tables.email'),
        size: 200,
      },
      {},
    ],
    [t]
  );

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);
  const onCreateStaff = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      const data = {
        name,
        email,
        phoneNumber,
        username: email,
        countryCode: 'am',
      };

      api
        .post('/companies', data)
        .then(({ data }) => {
          setAddNewStaff(false);
          setError(null);
          setName('');
          setEmail('');

          const audio = new Audio(sound);
          const info = t('notifications.userCreated');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          return setStaff([
            ...staff,
            {
              ...data,
              name,
            },
          ]);
        })
        .catch((err) => setError(t(`errors.${errToString(err)}`)));
    },
    [t, staff, email, name, phoneNumber]
  );
  const onStaffDelete = useCallback(() => {
    if (authCtx.user.id === selectedStaff.id) {
      setWarning(false);
      return alert('User cannot be deleted !');
    }

    api
      .delete(`/companies/${selectedStaff.id}`)
      .then(() => {
        setWarning(false);

        const audio = new Audio(sound);
        const info = `${selectedStaff.name} ${t('notifications.userDeleted')}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();

        return setStaff(
          staff
            .map((staff) => {
              if (selectedStaff.id === staff.id) {
                return {
                  ...staff,
                  deletedAt: new Date(),
                };
              }
              return staff;
            })
            .filter((staff) => staff.id !== selectedStaff.id)
        );
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t, authCtx.user, selectedStaff, staff]);
  const onChangeLimit = useCallback(
    (option) => {
      if (option.value === limit.value) {
        return;
      }

      setLimit(option);
      setOffset(0);
      setLoading(true);
      setCurrentPage(0);
    },
    [limit]
  );

  useEffect(() => {
    api
      .get(`/companies?offset=${offset}&limit=${limit.value}`)
      .then((res) => {
        setPagesCount(res.data.pagesCount);
        setStaff(res.data.results);
        setLoading(false);
      })
      .catch((err) => {
        console.log(t(`errors.${errToString(err)}`));
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [t, limit, offset]);
  useEffect(() => {
    api
      .get('/restaurant/integrations')
      .then((res) => {
        setIntegrations(res.data);
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, []);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            {!staffIsEditing && !addNewStaff && (
              <>
                <h2>{t('routes.staff')}</h2>
                <button
                  className='page_in_header_btn page_in_header_btn--add'
                  onClick={() => setAddNewStaff(true)}
                >
                  {t('general.addStaff')}
                </button>
              </>
            )}
            {!staffIsEditing && addNewStaff && (
              <>
                <h2>{t('general.addStaff')}</h2>
                <button
                  className='page_in_header_btn'
                  onClick={() => {
                    setAddNewStaff(false);
                    setName('');
                    setEmail('');
                    setError(null);
                  }}
                >
                  {t('general.back')}
                </button>
              </>
            )}
            {!addNewStaff && staffIsEditing && (
              <>
                <h2>
                  {t('general.edit')} {selectedStaff.name}
                </h2>
                <button
                  className='page_in_header_btn'
                  onClick={() => setStaffIsEditing(false)}
                >
                  {t('general.edit')}
                </button>
              </>
            )}
          </div>
          <div className='page_in_content'>
            {!staffIsEditing && addNewStaff && (
              <form className='form' onSubmit={onCreateStaff}>
                <div className='form_columns'>
                  <div className='form_column'>
                    <label htmlFor=''>{t('general.fullName')}</label>
                    <input
                      type='text'
                      value={name}
                      placeholder={t('general.fullName')}
                      onChange={(e) => onChangeField(e, setName)}
                    />
                  </div>
                  <div className='form_column'>
                    <label htmlFor=''>{t('general.email')}</label>
                    <input
                      type='text'
                      value={email}
                      placeholder={t('general.email')}
                      onChange={(e) => onChangeField(e, setEmail)}
                    />
                  </div>
                  <div className='form_column'>
                    <label htmlFor=''>{t('general.phoneNumber')}</label>
                    <input
                      type='text'
                      value={phoneNumber}
                      placeholder={t('general.phoneNumber')}
                      onChange={(e) => onChangeField(e, setPhoneNumber)}
                    />
                  </div>
                  {integrations[0]?.isActive && (
                    <div className='form_column'>
                      <label htmlFor=''>ID</label>
                      <input
                        type='text'
                        value={externalId}
                        placeholder='User ID in Poster'
                        onChange={(e) => onChangeField(e, setExternalId)}
                      />
                    </div>
                  )}
                </div>
                <div className='form_error form_error--left'>
                  {error && (
                    <div className='form_error_name'>
                      <PiWarningOctagonDuotone />
                      <p>{error}</p>
                    </div>
                  )}
                </div>
                <div className='form_actions'>
                  <button className='form_actions_btn' type={'submit'}>
                    {t('general.save')}
                  </button>
                </div>
              </form>
            )}
            {!addNewStaff && staffIsEditing && (
              <EditStaff
                setStaffIsEditing={setStaffIsEditing}
                selectedStaff={selectedStaff}
                staff={staff}
                setStaff={setStaff}
              />
            )}
            {!loading && (
              <>
                {!staffIsEditing && !addNewStaff && !!staff.length && (
                  <Table
                    route={false}
                    titles={StaffTitles}
                    rows={
                      staff.map((staff) => ({
                        id: staff.id,
                        name: <p>{staff.name}</p>,
                        email: <p>{staff.email}</p>,
                        actions: (
                          <div className='page_in_content_actions'>
                            <button
                              className='page_in_content_actions_btn page_in_content_actions_btn--edit'
                              onClick={() => {
                                setSelectedStaff(staff);
                                setWarning(false);
                                setAddNewStaff(false);
                                setStaffIsEditing(true);
                              }}
                            >
                              <AiTwotoneEdit /> {t('general.edit')}
                            </button>
                            <button
                              className='page_in_content_actions_btn page_in_content_actions_btn--delete'
                              onClick={() => {
                                setSelectedStaff(staff);
                                setAddNewStaff(false);
                                setStaffIsEditing(false);
                                setWarning(true);
                              }}
                            >
                              <IoMdClose /> {t('general.delete')}
                            </button>
                          </div>
                        ),
                      })) || []
                    }
                    hiddenFields={['id']}
                    loading={false}
                  />
                )}
                {!staffIsEditing && !addNewStaff && !!pagesCount && (
                  <div className='page_in_pagination'>
                    <Pagination
                      pagesCount={pagesCount}
                      setOffset={setOffset}
                      limit={limit}
                      limitPlaceholder={limit.label}
                      limits={LIMITS}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      onChange={onChangeLimit}
                    />
                  </div>
                )}
                {!staffIsEditing && !addNewStaff && !staff.length && (
                  <NoData title={t('noData.staff')} />
                )}
              </>
            )}
            {loading && <Loading />}
            {warning && (
              <Warning
                description={`${t('components.warning.mainTxt')} ${t('components.warning.block')} ${selectedStaff.name}`}
                onCancel={() => setWarning(false)}
                onSubmit={onStaffDelete}
              />
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Staff);
