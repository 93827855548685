import { memo, useCallback, useContext, useEffect, useState } from 'react';
import './index.scss';
import AuthContext from '../../../context/auth/auth-context';
import Wrapper from '../../../components/layout/wrapper';
import DropDown from '../../../components/drop-down';
import api from '../../../utils/api';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import Loading from '../../../components/loading';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../utils';

function Restaurant() {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();

  const [restaurantName, setRestaurantName] = useState(user.restaurant.name);
  const [restaurantAddress, setRestaurantAddress] = useState(
    user.restaurant.address
  );
  const [restaurantPhoneNumber, setRestaurantPhoneNumber] = useState(
    user.restaurant.restaurantInfo.phoneNumber
  );
  const [restaurantWiFiUsername, setRestaurantWiFiUsername] = useState(
    user.restaurant.restaurantInfo.wifiUsername
  );
  const [restaurantWiFiPassword, setRestaurantWiFiPassword] = useState(
    user.restaurant.restaurantInfo.wifiPassword
  );
  const [serviceFee, setServiceFee] = useState(
    user.restaurant.restaurantInfo.serviceFee
  );
  const [currencies, setCurrencies] = useState(null);
  const [currencyOption, setCurrencyOption] = useState(null);
  const [pending, setPending] = useState(false);

  const onSave = useCallback(
    (e) => {
      e.preventDefault();

      if (pending) {
        return;
      }

      setPending(true);

      const data = {
        address: restaurantAddress || user.restaurant.address,
        name: restaurantName || user.restaurant.name,
        restaurantInfo: {
          currency:
            currencyOption.value?.toLowerCase() ||
            user.restaurant.restaurantInfo.currency,
          highRatingUrl: user.restaurant.restaurantInfo.highRatingUrl,
          lowRatingUrl: user.restaurant.restaurantInfo.lowRatingUrl,
          phoneNumber:
            restaurantPhoneNumber || user.restaurant.restaurantInfo.phoneNumber,
          serviceFee: serviceFee || user.restaurant.restaurantInfo.serviceFee,
          wifiPassword:
            restaurantWiFiPassword ||
            user.restaurant.restaurantInfo.wifiPassword,
          wifiUsername:
            restaurantWiFiUsername ||
            user.restaurant.restaurantInfo.wifiUsername,
        },
      };

      api
        .put('/restaurant', data)
        .then(() => {
          const audio = new Audio(sound);
          const info = t('notifications.prefUpdate');
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          audio.play();
          setPending(false);
        })
        .catch((err) => {
          setPending(false);
          console.log(t(`errors.${errToString(err)}`));
          const audio = new Audio(sound);
          const info = `${errToString(err)}`;
          toast.info(info, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            type: 'error',
          });
          audio.play();
          console.log(errToString(err));
        });
    },
    [
      user,
      pending,
      t,
      restaurantName,
      restaurantAddress,
      restaurantPhoneNumber,
      restaurantWiFiPassword,
      restaurantWiFiUsername,
      serviceFee,
      currencyOption,
    ]
  );

  useEffect(() => {
    api
      .get('https://openexchangerates.org/api/currencies.json')
      .then(({ data }) => {
        const currencies = [];

        for (const key in data) {
          currencies.push({
            value: key,
            label: `${key} - ${data[key]}`,
          });
        }
        setCurrencies(currencies);
        return setCurrencyOption(
          currencies.find(
            ({ value }) =>
              value?.toLowerCase() ===
              user.restaurant.restaurantInfo.currency?.toLowerCase()
          )
        );
      })
      .catch((err) => {
        const audio = new Audio(sound);
        const info = `${errToString(err)}`;
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          type: 'error',
        });
        audio.play();
        console.log(errToString(err));
      });
  }, [user]);

  return (
    <Wrapper>
      <div className='page'>
        <div className='page_in'>
          <div className='page_in_header'>
            <h2>{t('routes.restaurant')}</h2>
          </div>
          <div className='page_in_content'>
            <form className='form' onSubmit={onSave}>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('dashboard.restaurant.name')}</label>
                  <input
                    type='text'
                    value={restaurantName}
                    placeholder={t('dashboard.restaurant.name')}
                    onChange={(e) => setRestaurantName(e.target.value)}
                  />
                </div>
                <div className='form_column'>
                  <label htmlFor=''>{t('dashboard.restaurant.address')}</label>
                  <input
                    type='text'
                    value={restaurantAddress}
                    placeholder={t('dashboard.restaurant.address')}
                    onChange={(e) => setRestaurantAddress(e.target.value)}
                  />
                </div>
                <div className='form_column'>
                  <label htmlFor=''>{t('dashboard.restaurant.phone')}</label>
                  <input
                    type='text'
                    value={restaurantPhoneNumber}
                    placeholder={t('dashboard.restaurant.phone')}
                    onChange={(e) => setRestaurantPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>
                    {t('dashboard.restaurant.wifi_username')}
                  </label>
                  <input
                    type='text'
                    value={restaurantWiFiUsername}
                    placeholder={t('dashboard.restaurant.wifi_username')}
                    onChange={(e) => setRestaurantWiFiUsername(e.target.value)}
                  />
                </div>
                <div className='form_column'>
                  <label htmlFor=''>
                    {t('dashboard.restaurant.wifi_password')}
                  </label>
                  <input
                    type='text'
                    value={restaurantWiFiPassword}
                    placeholder={t('dashboard.restaurant.wifi_password')}
                    onChange={(e) => setRestaurantWiFiPassword(e.target.value)}
                  />
                </div>
                <div className='form_column' />
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('dashboard.restaurant.currency')}</label>
                  <DropDown
                    hasCurrencyIcon={true}
                    placeholder={t('dashboard.restaurant.currency')}
                    position='top'
                    showClose={false}
                    options={currencies || []}
                    value={currencyOption || {}}
                    onChange={(option) => {
                      if (option.value === currencyOption.value) {
                        return;
                      }
                      setCurrencyOption(option);
                    }}
                  />
                </div>
                <div className='form_column' />
                <div className='form_column' />
              </div>
              <div className='form_columns'>
                <div className='form_column'>
                  <label htmlFor=''>{t('dashboard.restaurant.fee')}</label>
                  <input
                    type='number'
                    minLength={0}
                    maxLength={100}
                    value={serviceFee}
                    placeholder={t('dashboard.restaurant.fee')}
                    onChange={(e) => setServiceFee(e.target.value)}
                  />
                </div>
                <div className='form_column' />
                <div className='form_column' />
              </div>
              <div className='form_actions'>
                {!pending && (
                  <button className='form_actions_btn' type={'submit'}>
                    {t('general.save')}
                  </button>
                )}
                {pending && (
                  <button
                    onClick={() => {}}
                    className='form_actions_btn form_actions_btn--loading'
                  >
                    <Loading />
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default memo(Restaurant);
