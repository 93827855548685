import './index.scss';
import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../../../utils/api';
import { createPortal } from 'react-dom';
import { IoMdClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { errToString } from '../../../../../utils';
import { PiWarningOctagonDuotone } from 'react-icons/pi';

function AddCategory({
  setAddCategoryFormIsOpen,
  onRefreshCategories,
  primaryLng,
  languageId,
  selectedBranch,
}) {
  const { t } = useTranslation();
  const [newName, setNewName] = useState('');
  const [error, setError] = useState(null);

  const onChangeField = useCallback((e, setter) => {
    setter(e.target.value);
    setError(null);
  }, []);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setError(null);

      const data = {
        name: newName,
      };

      if (!newName) {
        return setError('Name cannot be empty!');
      }

      api
        .post(
          `/product-categories?languageId=${languageId}&branchId=${selectedBranch.id}`,
          data
        )
        .then(() => {
          setAddCategoryFormIsOpen(false);
          setError(null);
          return onRefreshCategories();
        })
        .catch((err) => setError(t(`errors.${errToString(err)}`)));
    },
    [
      languageId,
      t,
      newName,
      onRefreshCategories,
      setAddCategoryFormIsOpen,
      selectedBranch,
    ]
  );

  return createPortal(
    <div className='add_category' onClick={(e) => e.stopPropagation()}>
      <div className='add_category_in add_category_in--active'>
        <div className='add_category_in_header'>
          <div className='add_category_in_header_title'>
            <h2>
              {t('general.addCategory')} {primaryLng?.toUpperCase()}
            </h2>
            <img
              src={`https://hatscripts.github.io/circle-flags/flags/${primaryLng?.toLowerCase()}.svg`}
              width='48'
              alt={primaryLng}
            />
          </div>
          <i onClick={() => setAddCategoryFormIsOpen(false)}>
            <IoMdClose />
          </i>
        </div>
        <form className='form' onSubmit={onSubmit}>
          <div className='form_columns'>
            <div className='form_column'>
              <input
                type='text'
                value={newName}
                placeholder={t('general.categoryName')}
                onChange={(e) => onChangeField(e, setNewName)}
              />
            </div>
          </div>
          <div className='form_error'>
            {error && (
              <div className='form_error_name'>
                <PiWarningOctagonDuotone />
                <p>{error}</p>
              </div>
            )}
          </div>
          <div className='form_actions'>
            <button className='form_actions_btn' type={'submit'}>
              {t('general.save')}
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById('modal')
  );
}

AddCategory.propTypes = {
  setAddCategoryFormIsOpen: PropTypes.func.isRequired,
  onRefreshCategories: PropTypes.func.isRequired,
  primaryLng: PropTypes.string.isRequired,
};

export default memo(AddCategory);
