import './index.scss';
import { memo } from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
function Overlay({
  onClick = () => {},
  isTransparent = false,
  isWhite = false,
  isBLurDark = false,
  isBlack = false,
}) {
  return createPortal(
    <div
      className={cn(
        'overlay',
        { 'overlay--transparent': isTransparent },
        { 'overlay--white': isWhite },
        { 'overlay--blur': isBLurDark },
        { 'overlay--dark': isBlack }
      )}
      onClick={onClick}
    ></div>,
    document.getElementById('modal')
  );
}

Overlay.propTypes = {
  onClick: PropTypes.func,
  isTransparent: PropTypes.bool,
  isWhite: PropTypes.bool,
};

export default memo(Overlay);
