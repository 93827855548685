import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading';
import { IoEyeOutline } from 'react-icons/io5';
import { IoEyeOffOutline } from 'react-icons/io5';

function SignUp({
  onSignUp,
  setEmail,
  email,
  setPassword,
  password,
  setConfirmPassword,
  setPhoneNumber,
  phoneNumber,
  confirmPassword,
  setAuthMode,
  setTermsIsChecked,
  termsIsChecked,
  error,
  setError,
  loading,
}) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  return (
    <form onSubmit={onSignUp}>
      <h2>{t('auth.register')}</h2>
      <span>{t('auth.forCreatingAccount')}</span>
      <div className='form_error'>
        {error && (
          <div className='form_error_name'>
            <PiWarningOctagonDuotone />
            <p>{error}</p>
          </div>
        )}
      </div>
      <input
        autoComplete={'off'}
        value={phoneNumber}
        type='text'
        placeholder={t('general.phoneNumber')}
        onChange={(e) => {
          setPhoneNumber(e.target.value);
          setError(null);
        }}
      />
      <input
        autoComplete={'off'}
        value={email}
        type='text'
        placeholder={t('general.email')}
        onChange={(e) => {
          setEmail(e.target.value);
          setError(null);
        }}
      />
      <div className='form_input_wrapper'>
        <input
          autoComplete={'off'}
          value={password}
          type={showPassword ? 'text' : 'password'}
          placeholder={t('general.password')}
          onChange={(e) => {
            setPassword(e.target.value);
            setError(null);
          }}
        />
        <span
          className='form_input_wrapper_icon'
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
        </span>
      </div>
      <div className='form_input_wrapper'>
        <input
          autoComplete={'off'}
          value={confirmPassword}
          type={showRepeatPassword ? 'text' : 'password'}
          placeholder={t('general.confirmPassword')}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setError(null);
          }}
        />
        <span
          className='form_input_wrapper_icon'
          onClick={() => setShowRepeatPassword(!showRepeatPassword)}
        >
          {showRepeatPassword ? <IoEyeOutline /> : <IoEyeOffOutline />}
        </span>
      </div>
      <div className='auth_terms'>
        <input
          type='checkbox'
          className='auth_terms_checkbox'
          value={termsIsChecked}
          onChange={() => {
            setTermsIsChecked(!termsIsChecked);
            setError(null);
          }}
        />
        <div className='auth_terms_txt'>{t('auth.agree')}</div>
        <div
          className='auth_terms_btn'
          onClick={() => alert('Terms and Conditions')}
        >
          {t('auth.terms')}
        </div>
      </div>
      <button type={'submit'}>
        {!loading && t('auth.signUp')}
        {loading && <Loading />}
      </button>
      <div
        className='auth_forget_btn'
        onClick={() => {
          setAuthMode('sign-in');
          setError(null);
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setTermsIsChecked(false);
        }}
      >
        {t('auth.backToSignIn')}
      </div>
    </form>
  );
}

SignUp.propTypes = {
  onSignUp: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  setConfirmPassword: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  setAuthMode: PropTypes.func.isRequired,
  setTermsIsChecked: PropTypes.func.isRequired,
  termsIsChecked: PropTypes.bool.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
};
export default memo(SignUp);
