import { memo, useContext } from 'react';
import PreferencesContext from '../../../../../context/preferences/preferences-context';
import { createPortal } from 'react-dom';
import Overlay from '../../../../../components/layout/overlay';
import cn from 'classnames';

function ShopHeaderWiFi({ show, setShow, isDarkMode }) {
  const { list } = useContext(PreferencesContext);

  return (
    <>
      {show &&
        createPortal(
          <>
            <div
              className={cn('shop_header_wifi_modal', {
                'shop_header_wifi_modal--dark': false,
              })}
            >
              <div className='shop_header_wifi_modal_in shop_header_wifi_modal_in--active'>
                <h2>WiFi password</h2>
                <p>{list.restaurantInfo.wifiUsername}</p>
                <p>{list.restaurantInfo.wifiPassword}</p>
              </div>
            </div>
            <Overlay isTransparent={true} onClick={() => setShow(false)} />
          </>,
          document.getElementById('modal')
        )}
    </>
  );
}

export default memo(ShopHeaderWiFi);
