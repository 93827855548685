import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PiWarningOctagonDuotone } from 'react-icons/pi';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading';

function SignIn({
  setEmail,
  email,
  password,
  setPassword,
  onSignIn,
  setAuthMode,
  error,
  setError,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <form onSubmit={onSignIn}>
      <h2>{t('auth.signIn')}</h2>
      <span>{t('auth.toYourAccount')}</span>
      <div className='form_error'>
        {error && (
          <div className='form_error_name'>
            <PiWarningOctagonDuotone />
            <p>{error}</p>
          </div>
        )}
      </div>
      <input
        autoComplete={'off'}
        type='text'
        placeholder={t('general.email')}
        value={email}
        onChange={(e) => {
          setError(null);
          setEmail(e.target.value);
        }}
      />
      <input
        autoComplete={'off'}
        type='password'
        placeholder={t('general.password')}
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
          setError(null);
        }}
      />
      <button type={'submit'}>
        {!loading && t('auth.signIn')}
        {loading && <Loading />}
      </button>
      <div
        className='auth_forget_btn'
        onClick={() => {
          setAuthMode('forget');
          setError(null);
        }}
      >
        {t('auth.forgetYourPassword')}?
      </div>
      <div
        className='auth_sign_up_btn_mobile'
        onClick={() => {
          setAuthMode('sign-up');
          setError(null);
        }}
      >
        {t('auth.signUp')}?
      </div>
    </form>
  );
}

SignIn.propTypes = {
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  onSignIn: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  setAuthMode: PropTypes.func.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func,
};

export default memo(SignIn);
