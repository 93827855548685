import './index.scss';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ShopCategoryProduct from '../shop-home/shop-categories/shop-category-product';
import { IoIosArrowBack } from 'react-icons/io';
import ShopContext from '../../../context/shop/shop-context';
import api from '../../../utils/shop-api';
import Loading from '../../../components/loading';
import {
  errToString,
  removeDuplicates,
  toPriceWithCurrency,
} from '../../../utils';
import { useTranslation } from 'react-i18next';
import PreferencesContext from '../../../context/preferences/preferences-context';
import sound from '../../../assets/sounds/notification.wav';
import { toast } from 'react-toastify';
import SwipeableComponent from '../../../components/layout/swipeable';
import { BsTrash } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import ShopCategoryProductSuggestion from '../shop-home/shop-categories/shop-category-product-suggestion';
import ShopCategoryProductDetails from '../shop-home/shop-categories/shop-category-product/shop-category-product-details';
import { PiSmileySadThin } from 'react-icons/pi';

function ShopBasket({
  branchId,
  setBasketIsOpen,
  products,
  totalAmount,
  totalCount,
  basketIsOpen,
  type = 'default',
  lngId,
}) {
  const { list } = useContext(PreferencesContext);
  const { onClear } = useContext(ShopContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const tableId = pathname.split('/')[4];

  const [isMount, setIsMount] = useState(false);
  const [pending, setPending] = useState(false);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [showProductDetailsPage, setShowProductDetailsPage] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const basketProductIds = useMemo(
    () => products.map(({ id }) => id),
    [products]
  );

  const onSendOrder = useCallback(() => {
    setPending(true);

    const data = {
      tableId,
      products: products.map((product) => ({
        id: product.id,
        options: product.options.map(({ id }) => id),
        qty: product.qty,
        notes: product.note || null,
      })),
    };

    api
      .post(`/shop/${branchId}/orders`, data)
      .then(() => {
        setPending(false);
        setBasketIsOpen(false);
        onClear();
        const audio = new Audio(sound);
        const info = t('notifications.sendOrderFromShop');
        toast.info(info, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        audio.play();
      })
      .catch((err) => {
        setPending(false);
        console.log(t(`errors.${errToString(err)}`));
        alert(errToString(err));
      });
  }, [products, branchId, tableId, setBasketIsOpen, onClear, t]);
  const onClearBasket = useCallback(() => {
    onClear();
    const audio = new Audio(sound);
    const info = t('shop.basketClear');
    toast.info(info, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
    audio.play();
  }, [t, onClear]);

  useEffect(() => {
    setIsMount(true);
  }, []);
  useEffect(() => {
    if (!products?.length) {
      return setBasketIsOpen(false);
    }
  }, [products, setBasketIsOpen]);
  useEffect(() => {
    const productIds = removeDuplicates(products, 'id')
      .map(({ id }) => id)
      .join(', ');

    api
      .get(
        `/shop/${branchId}/products/suggestions?productIds=${productIds}&languageId=${lngId}`
      )
      .then(({ data }) => {
        setProductSuggestions(data);
      })
      .catch((err) => {
        setProductSuggestions([]);
        console.log(errToString(err));
      });
  }, [lngId, products, branchId]);

  useEffect(() => {
    if (totalCount === 0) {
      return setProductSuggestions([]);
    }
  }, [totalCount]);

  return (
    list &&
    createPortal(
      <SwipeableComponent
        setter={setBasketIsOpen}
        className={cn(
          'shop_basket',
          { 'shop_basket--show': isMount },
          { 'shop_basket--dark': false }
        )}
      >
        <div className='shop_basket_header'>
          {type === 'default' && (
            <IoIosArrowBack onClick={() => setBasketIsOpen(false)} />
          )}
          <h2 onClick={() => setBasketIsOpen(false)}>{t('shop.basket')}</h2>
          <p>{`${totalCount} ${totalCount === 1 ? t('shop.item') : t('shop.items')}, ${toPriceWithCurrency(Number(totalAmount), list.restaurantInfo.currency)}`}</p>
          <span onClick={onClearBasket}>
            <BsTrash />
          </span>
        </div>
        {totalCount > 0 && (
          <div className='shop_basket_in'>
            <ShopCategoryProduct
              products={products.filter(({ id }) =>
                basketProductIds.includes(id)
              )}
              showAddBtn={true}
              clickable={false}
              hasOverlay={basketIsOpen}
              isDarkMode={false}
            />
            {!!productSuggestions.length && (
              <>
                <h2 className='shop_basket_title'>
                  {t('general.anythingElse')}
                </h2>
                <ShopCategoryProductSuggestion
                  products={productSuggestions}
                  isDarkMode={false}
                  setSelectedProduct={setSelectedProduct}
                  setShowProductDetailsPage={setShowProductDetailsPage}
                />
                {showProductDetailsPage && (
                  <ShopCategoryProductDetails
                    product={selectedProduct}
                    setShowProductDetailsPage={setShowProductDetailsPage}
                    hasOverlay={false}
                    isDarkMode={false}
                  />
                )}
              </>
            )}
            {!pending && (
              <button
                className='shop_checkout_actions_btn'
                onClick={onSendOrder}
              >
                {t('shop.sendOrder')}
              </button>
            )}
            {pending && (
              <button className='shop_checkout_actions_btn shop_checkout_actions_btn--loading'>
                <Loading />
              </button>
            )}
          </div>
        )}
        {totalCount === 0 && (
          <div className='shop_basket_empty'>
            <p>{t('general.basketEmpty')}</p>
            <PiSmileySadThin />
          </div>
        )}
      </SwipeableComponent>,
      document.getElementById('modal')
    )
  );
}

ShopBasket.propTypes = {
  setBasketIsOpen: PropTypes.func.isRequired,
  branchId: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  totalAmount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  basketIsOpen: PropTypes.bool.isRequired,
};

export default memo(ShopBasket);
