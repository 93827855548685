import { memo } from 'react';
import './index.scss';
import PropTypes from 'prop-types';

function NoData({ title }) {
  return (
    <div className='no_data'>
      <h2>{title}</h2>
    </div>
  );
}

NoData.propTypes = {
  title: PropTypes.string.isRequired,
};
export default memo(NoData);
