import { useReducer, useCallback, memo } from 'react';
import AuthContext from './auth-context';

const defaultAuthState = {
  isAuth: null,
  user: null,
  signIn: () => {},
  signOut: () => {},
};

const authReducer = (state, action) => {
  if (action.type === 'AUTH') {
    return {
      ...state,
      isAuth: Boolean(action.payload.user),
      user: action.payload.user,
    };
  }
  if (action.type === 'NOT_AUTH') {
    return {
      ...state,
      isAuth: false,
      user: null,
    };
  }
  return defaultAuthState;
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, defaultAuthState);

  const signIn = useCallback(
    (user) => {
      dispatch({
        type: 'AUTH',
        payload: { user },
      });
    },
    [dispatch]
  );
  const signOut = useCallback(
    (user) => {
      dispatch({
        type: 'NOT_AUTH',
        payload: { user },
      });
    },
    [dispatch]
  );

  const authContext = {
    isAuth: state.isAuth,
    user: state.user,
    signIn: signIn,
    signOut: signOut,
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

export default memo(AuthProvider);
