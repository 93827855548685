import './index.scss';
import { memo } from 'react';
import cn from 'classnames';

function TerminalNav({
  categories,
  setSelectedCategoryId,
  selectedCategoryId,
}) {
  return (
    <ul className='terminal_nav'>
      {categories.map((category, index) => (
        <li
          key={index}
          className={cn('terminal_nav_item', {
            'terminal_nav_item--active': category.id === selectedCategoryId,
          })}
          onClick={() => setSelectedCategoryId(category.id)}
        >
          {category.name}
        </li>
      ))}
    </ul>
  );
}

export default memo(TerminalNav);
