import { useReducer, useCallback, memo } from 'react';
import PreferencesContext from './preferences-context';

const defaultPreferencesState = {
  preferences: null,
  set: () => {},
  update: () => {},
};

const preferencesReducer = (state, action) => {
  if (action.type === 'SET_PREFERENCES') {
    return {
      ...state,
      preferences: action.payload.preferences,
    };
  }
  if (action.type === 'UPDATE_PREFERENCE') {
    return {
      ...state,
      preferences: {
        ...state.preferences,
        [action.payload.preference.name]: action.payload.preference,
      },
    };
  }

  return defaultPreferencesState;
};

const PreferencesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    preferencesReducer,
    defaultPreferencesState
  );

  const setPreferences = useCallback(
    (preferences) => {
      dispatch({
        type: 'SET_PREFERENCES',
        payload: { preferences },
      });
    },
    [dispatch]
  );
  const updatePreference = useCallback(
    (preference) => {
      dispatch({
        type: 'UPDATE_PREFERENCE',
        payload: { preference },
      });
    },
    [dispatch]
  );

  const preferencesContext = {
    list: state.preferences,
    set: setPreferences,
    update: updatePreference,
  };

  return (
    <PreferencesContext.Provider value={preferencesContext}>
      {children}
    </PreferencesContext.Provider>
  );
};

export default memo(PreferencesProvider);
