export const LIMITS = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 },
];

export const LANGUAGES = [
  { label: 'Հայերեն', value: 'am' },
  { label: 'Русский', value: 'ru' },
  { label: 'English', value: 'us' },
];
