import { useReducer, useCallback, memo } from 'react';
import TerminalContext from './terminal-context';

const defaultTerminalState = {
  products: [],
  onAddItem: () => {},
  onRemoveItem: () => {},
};

const terminalReducer = (state, action) => {
  if (action.type === 'ADD_ITEM') {
    const productsObj = {};
    for (const product of state.products) {
      const key = `${product.id}:${product.options.map(({ id }) => id).join(',')}`;
      productsObj[key] = {
        ...product,
      };
    }

    const products = [];
    const key = `${action.payload.product.id}:${action.payload.product.options.map(({ id }) => id).join(',')}`;

    if (productsObj[key]) {
      productsObj[key].qty += 1;
    }
    if (!productsObj[key]) {
      productsObj[key] = {
        ...action.payload.product,
        qty: 1,
      };
    }

    for (const key in productsObj) {
      products.push(productsObj[key]);
    }

    const data = {
      ...state,
      products,
    };

    return data;
  }
  if (action.type === 'REMOVE_ITEM') {
    const productsObj = {};
    for (const product of state.products) {
      const key = `${product.id}:${product.options.map(({ id }) => id).join(',')}`;
      productsObj[key] = {
        ...product,
      };
    }

    const products = [];
    const key = `${action.payload.product.id}:${action.payload.product.options.map(({ id }) => id).join(',')}`;

    if (productsObj[key]) {
      productsObj[key].qty -= 1;
    }

    for (const key in productsObj) {
      products.push(productsObj[key]);
    }

    const data = {
      ...state,
      products: products.filter(({ qty }) => qty > 0),
    };

    return data;
  }
  if (action.type === 'CLEAR') {
    return {
      ...state,
      products: [],
    };
  }

  return defaultTerminalState;
};

const TerminalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(terminalReducer, defaultTerminalState);

  const onAddItem = useCallback(
    (product) => {
      dispatch({
        type: 'ADD_ITEM',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onRemoveItem = useCallback(
    (product) => {
      dispatch({
        type: 'REMOVE_ITEM',
        payload: { product },
      });
    },
    [dispatch]
  );
  const onClear = useCallback(
    (product) => {
      dispatch({
        type: 'CLEAR',
        payload: { product },
      });
    },
    [dispatch]
  );

  const terminalContext = {
    products: state.products,
    onAddItem: onAddItem,
    onRemoveItem: onRemoveItem,
    onClear: onClear,
  };

  return (
    <TerminalContext.Provider value={terminalContext}>
      {children}
    </TerminalContext.Provider>
  );
};

export default memo(TerminalProvider);
